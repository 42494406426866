import React, { useState, useEffect } from 'react';
import Header from './include/Header'
import { Button, Grid, Box } from '@mui/material'
import Footer from './include/Footer'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import widgetlogo from '../images/widget-logo.png'
// import { Box } from '@mui/material';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import blogr1 from '../images/blogr1.webp'
import blogth from '../images/blogth.webp'
import rbro3 from '../images/rbro3.webp'
import c8 from '../images/c8.png'
import c9 from '../images/c9.png'
import s1 from '../images/s1.webp'
import s2 from '../images/s2.webp'
import r1 from '../images/r1.png'
import r2 from '../images/r2.png'
import { consts } from '../constant';

const setHead = () => {
    document.title = "ThoreCoin/insights";

    // Update meta tags
    const metaDesc = document.querySelector('meta[name="description"]');
    if (metaDesc) {
      metaDesc.setAttribute('content','Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
      ogTitle.setAttribute('content',"ThoreCoin/insights");
    }

    const ogDesc = document.querySelector('meta[property="og:description"]');
    if (ogDesc) {
      ogDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const ogImage = document.querySelector('meta[property="og:image"]');
    if (ogImage) {
      ogImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
    }

    const ogUrl = document.querySelector('meta[property="og:url"]');
    if (ogUrl) {
      ogUrl.setAttribute('content', `${consts.MainUrl}/insights` || window.location.href);
    }

    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    if (twitterTitle) {
      twitterTitle.setAttribute('content', "ThoreCoin/insights");
    }

    const twitterDesc = document.querySelector('meta[name="twitter:description"]');
    if (twitterDesc) {
      twitterDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const twitterImage = document.querySelector('meta[name="twitter:image"]');
    if (twitterImage) {
      twitterImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
    }
  };

  setHead();


const style = {
    // position: 'absolute',
    right: '45px',
    bottom: '100px',
    // transform: 'translate(-50%, -50%)',
    width: 430,
    height: 700,
    bgcolor: 'background.paper',
    // boxShadow: 24,
    p: 0,
    borderRadius: '10px',
    outline: 'none',

    '@media (max-width: 575.98px)': {
        width: 350,
    },

    '@media (max-width: 1999.98px)': {
        height: 'fit-content',
        width: 350,
    },

};

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        p: 4,
        borderRadius: '4px',
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    sidebarcls: {
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        top: '0px',
        padding: '0px !important',
    }

});



function Insight() {
    document.title = "ThoreCoin/insights";
    const classes = useStyles();

    const [walletss, wallets] = useState("")


    const solProviders = (data) => {
        // setSolProviders(data)
    }

    const eTHProviders = (data) => {
        // setETHProviders(data)
    }
    const [address, setAddress] = useState("")
    const [net, setNet] = useState("")
    useEffect(() => {
        if (net === "") {
            setNet(localStorage.getItem("Network"))
        } else {
            setNet("")
        }
    }, [])

    // const card1 = [
    //     { img: `${c1}`, title: 'SPOT Trading', desc: 'Access real-time market data and trade a wide range of digital assets with precision.' },
    //     { img: `${c2}`, title: 'P2P Trading', desc: 'Securely trade directly with other users, enjoying the flexibility and privacy of peer-to-peer transactions' },
    //     { img: `${c3}`, title: 'OTC Trading', desc: 'Execute large-volume trades with our Over-The-Counter service, offering personalized support and competitive rates.' },
    // ]
    // ..
    AOS.init();
    const [input, setInput] = useState('');

    const [response, setResponse] = useState([]);
    const [inputda, setInputda] = useState([])
    //   const openai = new OpenAI({
    //     organization: "org-g0g0ozr3yvNOc2CiWVZp7ycP",
    //     project: "$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",
    // });

    const onKeyDown = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            sendMessage();
        }
    }

    const sendMessage = async (message = input) => {
        try {
            const apiUrl = 'https://api.openai.com/v1/chat/completions'; // Update with the correct API endpoint
            const apiKey = 'sk-proj-5PQbhTY5rhpQcgNZ1Aa6T3BlbkFJbhyW29XpjjnWFoKbcEtr'; // Replace with your actual API key
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
            };

            const requestBody = {
                model: "gpt-3.5-turbo",
                messages: [{ role: 'system', content: message }],
                max_tokens: 150,

            };
            const { data } = await axios.post(apiUrl, requestBody, { headers });

            setInput('')

            let da
            da = data.choices[0].message.content;
            setResponse(oldArray => ([...oldArray, message]))
            setResponse(oldArray => ([...oldArray, da]));
        } catch (error) {
            console.error('Error sending message:', error);
        }

    };





    const [prebuild, setPrebuild] = useState([
        { name: 'What is Thore?' },
        { name: 'Tell me about Thorecoin' },
        { name: 'What is Thore Network' }
    ])

    const handlePrebuild = (value) => {
        setInput(value);
        console.log(value, 'valval');
        // sendMessage(value)
    }
    return (
        <div className='landing-page-main presale-mail-page'>
            <ToastContainer />
            <Header setToken={net} solProviders={solProviders} eTHProviders={eTHProviders} setAddress={setAddress} wallets={wallets} />

            <div
                className='banner-Thorecoin'
            >

                <Grid container spacing={1} className='thorecoin-banner' sx={{ justifyContent: 'center' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='display-1'>
                            <div>
                                <div className='insight-text'>
                                    Thorecoin Insights AI Search Engine
                                </div>
                                <div className='revo'>
                                    Revolutionizing Crypto Market Analysis
                                </div>
                                <div className='revo-desc'>
                                    Welcome to Thorecoin Insights, the ultimate AI-powered search engine designed specifically for the cryptocurrency market. Our advanced AI technology delivers precise, real-time data and insights, empowering you to make informed decisions in the fast-paced world of digital assets.
                                </div>
                            </div>
                            <div className='blogr1'>
                                <img src={blogr1} alt='blohg' />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={10} className='insight-widget'>
                        <Box sx={style} className='widget_chart insign'>
                            <div className='bot_head'>
                                <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                                    <img src={widgetlogo} alt="logobudu" />Thorecoin Insights
                                </Typography>
                                {/* <div className='min-icon'>
                                    <LightTooltip title="Minimize" placement="left">
                                        <KeyboardArrowDownIcon />
                                    </LightTooltip>
                                </div> */}
                            </div>

                            {/* <div className='offline-message'>
                                <span>We are online</span>
                            </div> */}





                            {/* 
            <div className='message-body_default'>
              <Tooltip title={<Typography>How can I buy Thorecoin?</Typography>} arrow>
                <div className='widget_content default'>
                  <p class="message-content">How can I buy Thorecoin?</p>
                </div>
              </Tooltip>
              <Tooltip title={<Typography>What is Thorecoin AI Search Engine?</Typography>} arrow>
                <div className='widget_content default'>
                  <p class="message-content">What is Thorecoin AI Search Engine?</p>
                </div>
              </Tooltip>

            </div> */}

                            <div className='message_flex-grid insig'>

                                <div className='message-body'>

                                    {/* { inputda?.map(item =>(<div className='widget_content right'>
               <span class="message-content">{item}</span>
              </div>))} */}

                                    {response?.map((item, index) => {
                                        return (
                                            <>{index % 2 != 0 ?
                                                <div className='widget_content left'>
                                                    <span class="message-content">{item}</span>
                                                </div>
                                                :
                                                <div className='widget_content right'>
                                                    <span class="message-content">{item}</span>
                                                </div>
                                            }
                                            </>
                                        )

                                    })}
                                    {/* 
              <div className='widget_content right'>
                <span class="message-content">Could you provide some details about the problem you encountered?</span>
              </div>

              <div className='widget_content left'>
                <span class="message-content">Could you provide some details about the problem?</span>
              </div>

              <div className='widget_content right'>
                <span class="message-content">Could you provide some details about the problem you encountered?</span>
              </div>

              <div className='widget_content left'>
                <span class="message-content">Could you provide some details about the problem?</span>
              </div> */}
                                </div>
                            </div>
                            <div className='message-body_default new2'>
                                {prebuild.map((row, ind) => {
                                    return (
                                        <Tooltip title={<Typography>{row.name}</Typography>} arrow>
                                            <div className='widget_content default insight' >
                                                <p class="message-content" onClick={() => handlePrebuild(row.name)}>{row.name}</p>

                                            </div>
                                        </Tooltip>
                                    )
                                })}

                            </div>

                            <div className='chat-foot insi'>

                                <TextField id="outlined-basic" placeholder="Enter your message..." variant="outlined" value={input}
                                    onChange={(e) => setInput(e.target.value)} onKeyDown={onKeyDown} />
                                <Button className='send_btn' onClick={() => sendMessage()}><SendIcon /></Button>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1} className='nopadcont'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='ket-feat-ex'>
                            Key Features:
                        </div>
                        <div className='display-1 react2'>
                            <div className='realt'>
                                <div className='raelt-tt' >
                                    Real-Time Data Analysis
                                </div>
                                <div className='real-desc'>
                                    Instant Updates: Stay ahead with real-time updates on cryptocurrency prices, market trends, and news.
                                    Comprehensive Coverage: Access data from a wide range of sources, including major exchanges, news outlets, and social media platforms.
                                </div>
                            </div>
                            <div className='blogth'>
                                <img src={blogth} alt='blog' />
                            </div>
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='ket-feat-ex margin-top'>
                            <span>Advanced AI </span>Algorithms
                        </div>
                        <div className='display-1 react2'>
                            <div className='realt dem' >
                                <div className='raelt-tt' >
                                    Predictive Analytics:
                                </div>
                                <div className='real-desc'>
                                    Utilize cutting-edge AI algorithms to predict market movements and identify potential investment opportunities.
                                </div>
                            </div>
                            <div className='realt dem'   >
                                <div className='raelt-tt' >
                                    Sentiment Analysis:
                                </div>
                                <div className='real-desc'>
                                    Gauge market sentiment through AI-driven analysis of news articles, social media posts, and forum discussions.
                                </div>
                            </div>
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='ket-feat-ex lines margin-top'>
                            <span>User-Friendly Interface</span>
                            {/* <hr /> */}
                        </div>
                        <div className='react2'>
                            <div className='realt dem2'>
                                <div className='raelt-tt' >
                                    Intuitive Design:
                                </div>
                                <div className='real-desc'>
                                    Navigate the platform easily with our clean, user-friendly interface designed for both beginners and experienced traders.
                                </div>
                            </div>
                            <div className='realt dem2' >
                                <div className='raelt-tt' >
                                    Customizable Dashboard:
                                </div>
                                <div className='real-desc'>
                                    Personalize your experience by customizing your dashboard to display the information most relevant to you.
                                </div>
                            </div>
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='display-1 deepinsght'>
                            <div className='padd'>
                                <div className='deeopsight-te'>
                                    Deep Insights and Reporting
                                </div>
                                <div className='deop-tc'>
                                    Detailed Reports:
                                </div>
                                <ul className='despdesc'>
                                    <li>
                                        Generate in-depth reports on specific cryptocurrencies, market sectors, or overall market trends.
                                    </li>
                                </ul>
                                <div className='deop-tc'>
                                    Historical Data:
                                </div>
                                <ul className='despdesc'>
                                    <li>
                                        Access extensive historical data to analyze past market behavior and make informed predictions.
                                    </li>
                                </ul>
                            </div>
                            <div className='rbro3'>
                                <img src={rbro3} alt='rbh' />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='intergen'>
                            <div className='intergen-inner-main'>
                                <div className='intergen-text'>
                                    Integration and Compatibility

                                </div>
                                <div className='display-2'>
                                    <div>
                                        <div className='inetr-sub'>
                                            Detailed Reports:
                                        </div>
                                        <ul className='inter-desc'>
                                            <li>
                                                Integrate Thorecoin Insights with your existing tools and platforms via our comprehensive API.
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div className='inetr-sub'>
                                            Cross-Platform Compatibility:
                                        </div>
                                        <ul className='inter-desc'>
                                            <li>
                                                Enjoy seamless access to Thorecoin Insights on your desktop, tablet, or smartphone.
                                            </li>
                                        </ul>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='security margin-top'>
                            Security and Privacy
                        </div>
                        <div className='display-2 securi'>
                            <div className='secu x1'>
                                <img src={s1} alt='s1' />
                                <div className='clp-div'>
                                    <div className='datasec'>
                                        Data Security:
                                    </div>

                                    <div className='datadesc'>
                                        Protect your information with advanced encryption and robust security protocols.
                                    </div>
                                </div>
                            </div>
                            <div className='secu x2'>
                                <img src={s2} alt='s1' />
                                <div className='clp-div'>
                                    <div className='datasec'>
                                        Privacy Assurance:
                                    </div>

                                    <div className='datadesc'>
                                        We prioritize your privacy, ensuring your data is never shared without your explicit consent.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='border margin-top'>
                            <div className='community '>
                                Community and Support
                            </div>
                            <div className='display-1 spport'>
                                <div >
                                    <div className='f11'>
                                        <img src={c8} />
                                    </div>
                                    <div className='supo'>
                                        <div className='raelt-tt'>
                                            Active Community:
                                        </div>
                                        <div className='real-desc'>
                                            Join a vibrant community of crypto enthusiasts and experts to share insights and strategies.
                                        </div>
                                    </div>
                                </div>
                                <div className='bodf'>

                                </div>
                                <div>
                                    <div className='f11'>
                                        <img src={c9} />
                                    </div>
                                    <div className='supo'>
                                        <div className='raelt-tt'>
                                            24/7 Support:
                                        </div>

                                        <div className='real-desc'>
                                            Receive round-the-clock support from our dedicated customer service team.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='display-2 educt'>
                            <div >

                                <div className='supo2'>
                                    <div className='raelt-tt'>
                                        Learning Center
                                    </div>
                                    <div className='real-desc'>
                                        Enhance your knowledge with a wealth of educational resources, including articles, tutorials, and webinars.
                                    </div>
                                </div>
                            </div>
                            <div className='border-circle'>
                                Educational Resources
                            </div>
                            <div >

                                <div className='supo2'>
                                    <div className='raelt-tt'>
                                        AI Insights
                                    </div>
                                    <div className='real-desc'>
                                        Understand the intricacies of AI and its application in cryptocurrency trading through our specialized content.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='relative margin-top'>
                        <div className='defi'>
                            <div className='defi-head'>
                                Seamless DeFi Access
                            </div>
                            <div className='defi-desc'>
                                Explore decentralized finance (DeFi) opportunities directly through Thorecoin Insights.
                            </div>
                        </div>
                        <div className='bordersf'>
                            <div className='r1-i'>
                                <img src={r1} />
                            </div>
                        </div>
                        <div className='defi'>
                            <div className='defi-head'>
                                Yield Farming and Staking
                            </div>
                            <div className='defi-desc'>
                                Participate in yield farming and staking with detailed insights and analytics.
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='relative margin-top'>
                        <div className='defi2'>
                            <div className='defi-head'>
                                Continuous Updates
                            </div>
                            <div className='defi-desc'>
                                Benefit from continuous updates and improvements to our AI algorithms and features.
                            </div>
                        </div>
                        <div className='bordersf2'>
                            <div className='r1-dem'>
                                <img src={r2} />
                            </div>
                        </div>
                        <div className='defi2'>
                            <div className='defi-head'>
                                Innovative Solutions
                            </div>
                            <div className='defi-desc'>
                                Stay at the forefront of crypto market analysis with our commitment to innovation and cutting-edge technology.
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className='joinuus-btn'>
                    <div className='join-tex-bt'>
                        Unlock the full potential of the cryptocurrency market with Thorecoin Insights, your trusted AI search engine for comprehensive, real-time crypto analysis.
                        <div className='join-brn'>
                            <Button>
                                Join us
                            </Button>
                        </div>
                    </div>


                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Insight
