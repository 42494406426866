import React, { useState, useEffect } from 'react';
import Header from './include/Header'
import Footer from './include/Footer'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom'
import soln2 from '../images/soln-2.webp'
import soln3 from '../images/soln-3.webp'
import soln4 from '../images/soln-4.webp'
import equitylogo from '../images/equity-logo.webp'
import equity from '../images/equity.webp'
import nsdl from '../images/nsdl.webp'
import herolines from '../images/hero-lines.webp'
import aiblockchain from '../images/aiblockchain.webp'
import equitybannerright from '../images/equitybannerright.webp'
import aiblc from '../images/aiblc.webp'
import vertical1 from '../images/vertical-1.webp'
import vertical2 from '../images/vertical-2.webp'
import vertical3 from '../images/vertical-3.webp'
import { consts } from '../constant';

import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const setHead = () => {
    document.title = "ThoreCoin/equity";

    // Update meta tags
    const metaDesc = document.querySelector('meta[name="description"]');
    if (metaDesc) {
      metaDesc.setAttribute('content','Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
      ogTitle.setAttribute('content',"ThoreCoin/equity");
    }

    const ogDesc = document.querySelector('meta[property="og:description"]');
    if (ogDesc) {
      ogDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const ogImage = document.querySelector('meta[property="og:image"]');
    if (ogImage) {
      ogImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
    }

    const ogUrl = document.querySelector('meta[property="og:url"]');
    if (ogUrl) {
      ogUrl.setAttribute('content', `${consts.MainUrl}/equity` || window.location.href);
    }

    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    if (twitterTitle) {
      twitterTitle.setAttribute('content', "ThoreCoin/equity");
    }

    const twitterDesc = document.querySelector('meta[name="twitter:description"]');
    if (twitterDesc) {
      twitterDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const twitterImage = document.querySelector('meta[name="twitter:image"]');
    if (twitterImage) {
      twitterImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
    }
  };

  setHead();


// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    sidebarcls: {
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        top: '0px',
        padding: '0px !important',
    }

});

const Equity = () => {

   
    document.title = "ThoreCoin/equity";


    const classes = useStyles();

    const [selected, setSelected] = useState(0)
    // const [selectedTab, setSelectedTab] = useState(0)
    const [formStatus, setFormStatus] = useState(false)

    const handleFormOpen = () => { setFormStatus(true) }
    const handleFormClose = () => { setFormStatus(false) }

    const toggle = (i) => {
        console.log(i)
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }


    const data = [
        { title: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.', answer: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.' },
        { title: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.', answer: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.' },
        { title: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.', answer: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.' },
        { title: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.', answer: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.' },
        { title: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.', answer: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.' },
        { title: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.', answer: 'Lorem ipsum dolor sit amet consectetur. Diam viverra sem mauris imperdiet enim. Mi molestie nunc sed vitae nunc. Vitae.' },
    ]


    const options = {
        animationEnabled: true,
        data: [{
            type: "doughnut",
            showInLegend: false,
            indexLabel: "{name}: {y}",
            yValueFormatString: "#,###'%'",
            startAngle: 300,
            dataPoints: [
                { name: "", y: 35, color: "rgba(68, 82, 154, 1)" },
                { name: "", y: 15, color: "rgba(90, 107, 195, 1)" },
                { name: "", y: 15, color: "rgba(111, 131, 236, 1)" },
                { name: "", y: 15, color: "rgba(145, 162, 250, 1)" },
                { name: "", y: 10, color: "rgba(185, 196, 255, 1)" },
                { name: "", y: 5, color: "rgba(197, 205, 250, 1)" },
                { name: "", y: 5, color: "rgba(226, 231, 255, 1)" }
            ]
        }]
    }



    const targetDate = new Date("2024-12-31T23:59:59");

    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        if (difference <= 0) {
            return {};
        }
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
    }, [targetDate]);
    const [walletss, wallets] = useState("")


    const solProviders = (data) => {
        // setSolProviders(data)
    }

    const eTHProviders = (data) => {
        // setETHProviders(data)
    }
    const [address, setAddress] = useState("")
    const [net, setNet] = useState("")
    useEffect(() => {
        if (net === "") {
            setNet(localStorage.getItem("Network"))
        } else {
            setNet("")
        }
    }, [])

    return (
        <div className='landing-page-main presale-mail-page'>
            <ToastContainer />
            <Header setToken={net} solProviders={solProviders} eTHProviders={eTHProviders} setAddress={setAddress} wallets={wallets} />
            <div className='banner-Thorecoin banner-Thorecoin-equity'>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} className='thorecoin-banner'>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='thorecoin-banner-left thorecoin-banner-left-equity'>
                            <h3>Thorecoin Unlisted Share</h3>
                            <div className='equity-banner-logo'>
                                <img src={equitylogo} alt="bannerequityleft" />
                                <p>Thore Network PVT LTD</p>
                            </div>
                            <div className='equity-banner-herolines'>
                                <img src={herolines} alt="bannerequityleft" />
                            </div>
                            <div className='equity-banner-left-img'>
                                <Link to='https://equityzen.com/company/thorenetwork/ '><img src={equity} alt="bannerequityleft" /></Link>
                                <Link> <img src={nsdl} alt="bannerequityleft" /></Link>
                            </div>
                            <div className='equity-banner-btns-outer'>
                                <div className='equity-banner-btns'>
                                    <Link className='common-button-link'><span>Buy</span></Link>
                                    <Link className='common-button-link'><span>Sell</span></Link>
                                </div>
                            </div>





                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={1} xl={1} className=''></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className='thorecoin-banner-right'>
                            <Item className={classes.sidebarcls} id="presale-form-thorecoin-banner-right-form-id">
                                <div className='equity-banner-right-img'>
                                    <img src={equitybannerright} alt="equitybannerright" />
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </div>


            <div className='card-value-overview'>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={1} xl={1}> </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                            <Grid container spacing={1} className='bg-img-card'>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='card-total-box'>
                                        <div className='card-inner-box'>
                                            <label>Company Name</label>
                                            <p>Thore Network Private Limited</p>
                                        </div>

                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='card-total-box'>
                                        <div className='card-inner-box'>
                                            <label>Scrip Name</label>
                                            <p>THR</p>
                                        </div>

                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='card-total-box'>
                                        <div className='card-inner-box'>
                                            <label>No. of Outstanding Shares</label>
                                            <p>75,000</p>
                                        </div>

                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='card-total-box'>
                                        <div className='card-inner-box'>
                                            <label>PAN No.</label>
                                            <p>~</p>
                                        </div>

                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='card-total-box'>
                                        <div className='card-inner-box'>
                                            <label>Price</label>
                                            <p>10000</p>
                                        </div>

                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='card-total-box'>
                                        <div className='card-inner-box'>
                                            <label>EPS</label>
                                            <p>~</p>
                                        </div>

                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='card-total-box'>
                                        <div className='card-inner-box'>
                                            <label>High/Low</label>
                                            <p>9999/10000</p>
                                        </div>

                                    </div>
                                </Grid>


                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <div className='card-total-box'>
                                        <div className='card-inner-box'>
                                            <label>Book value</label>
                                            <p>7506.12</p>
                                        </div>

                                    </div>
                                </Grid>


                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={1} xl={1}> </Grid>
                    </Grid>
                </Box>
            </div>


            <div className='equity-overview'>
                <Box sx={{ flexGrow: 1 }} >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className='outsmart-inner-thorecoin-left'>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className='outsmart-inner-thorecoin-right bcg'>
                            <label>Company Overview</label>
                            <h4>Thore Network PVT LTD</h4>
                            <p>Thore Network PVT LTD is a pioneering organization dedicated to transforming the digital landscape through innovative technologies in the fields of Artificial Intelligence (AI) and Blockchain. Our company is structured around three dynamic verticals: Thorecoin AI Token, AI Search Engine, and AI Education. In addition, we offer comprehensive AI & Blockchain as a Service (BaaS) solutions to both government and private sectors.</p>
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <div className='equity-overview-verical'>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='outsmart-inner-thorecoin-right'>
                            <h4 className='equity-overview-verical-heading'><span>Verticals</span></h4>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className='vertical-card-outer vertical-card-1'>
                            <div className='vertical-inner-card'>
                                <div className='vertical-card-head'><div className='vert-card-head-icon'><img src={vertical1} alt='' /></div><h5>Thorecoin AI Token</h5></div>
                                <div className='vertical-card-body'>
                                    <p>Thorecoin AI Token represents the core of our digital asset ecosystem. As a cryptocurrency, it is designed to facilitate seamless transactions within the Thore Network, promoting a decentralized and efficient financial ecosystem. The Thorecoin AI Token leverages advanced AI algorithms to enhance security, optimize transaction speeds, and ensure a robust, scalable blockchain infrastructure.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className='vertical-card-outer vertical-card-2'>
                            <div className='vertical-inner-card'>
                                <div className='vertical-card-head'><div className='vert-card-head-icon'><img src={vertical2} alt='' /></div><h5>AI Search Engine</h5></div>
                                <div className='vertical-card-body'>
                                    <p>Our AI Search Engine is a cutting-edge platform that redefines the way information is retrieved and utilized. By integrating sophisticated AI algorithms, our search engine delivers highly accurate and contextually relevant results. This tool is designed to cater to diverse user needs, from casual inquir</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className='vertical-card-outer vertical-card-3'>
                            <div className='vertical-inner-card'>
                                <div className='vertical-card-head'><div className='vert-card-head-icon'><img src={vertical3} alt='' /></div><h5>AI Education</h5></div>
                                <div className='vertical-card-body'>
                                    <p>Thorecoin AI Token represents the core of our digital asset ecosystem. As a cryptocurrency, it is designed to facilitate seamless transactions within the Thore Network, promoting a decentralized and efficient financial ecosystem. The Thorecoin AI Token leverages advanced AI algorithms to enhance security, optimize transaction speeds, and ensure a robust, scalable blockchain infrastructure.</p>
                                </div>
                            </div>
                        </Grid>

                    </Grid>
                </Box>
            </div>

            <div className='token-section token-section-ai-blockchain'>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} className='tokenomics'>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='token-section-right equity-ai-blockchain'>
                            <Grid container spacing={1} className='tokenomics-inner'>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='token-section-heading'>
                                    <h3><span>AI & Blockchain</span> <label>as a Service (BaaS)</label></h3>
                                    <p>Thore Network PVT LTD extends its expertise through AI & Blockchain as a Service (BaaS) solutions, catering to both government and private sector needs. Our BaaS offerings include:</p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className='solutions-inner'>
                                        <div><img src={aiblc} alt="soln2" /><span>Customized AI Solutions</span></div>
                                        <p>Tailored AI models and systems to meet specific industry requirements, enhancing operational efficiency and decision-making processes.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className='solutions-inner'>
                                        <div><img src={aiblc} alt="soln2" /><span>Blockchain Development</span></div>
                                        <p>Secure, scalable blockchain solutions designed to facilitate transparent and tamper-proof transactions across various industries.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className='solutions-inner'>
                                        <div><img src={aiblc} alt="soln3" /><span>Integration Services</span></div>
                                        <p>Seamless integration of AI and blockchain technologies into existing systems, ensuring minimal disruption and maximum value.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className='solutions-inner'>
                                        <div><img src={aiblc} alt="soln4" /><span>Consultancy and Support</span></div>
                                        <p>Expert advisory services and continuous support to help organizations navigate the complexities of AI and blockchain adoption.</p>
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='token-section-left'>
                            <div className='solutions'><img src={aiblockchain} alt="ai-blockchain" /></div>
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <div className='What-is-Thorecoin-outsmart equity-conclusion'>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='howthrn-heading'>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='howthrn bcg'>
                            <h3>Conclusion</h3>
                            <p>Thore Network PVT LTD is at the forefront of technological innovation, driving advancements in AI and blockchain technologies. Through our diverse verticals and comprehensive BaaS solutions, we are committed to creating a more connected, efficient, and intelligent digital world. Join us on our journey to explore the limitless possibilities of AI and blockchain.</p>
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <Footer />
        </div>
    )
}

export default Equity
