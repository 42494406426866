import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Landing from './Components/Landing';
import Presale from './Components/Presale';
import Equity from './Components/Equity';
import Exchange from './Components/Exchange';
import Analytics from './Components/Analytics';
import Insight from './Components/Insight';
import Blog from './Components/Blog';
import BlogInner from './Components/BlogInner';
import MetaTesting from './Components/MetaTesting';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' exact element={<Landing />} />
          <Route path='/presale' element={<Presale />} />
          <Route path='/equity' element={<Equity />} />
          <Route path='/exchange' element={<Exchange />} />
          <Route path='/analytics' element={<Analytics />} />
          <Route path='/insights' element={<Insight />} />
          {/* <Route path='/blog' element={<Blog />} /> */}
          {/* <Route path='/bloginner/:id' element={<BlogInner />} /> */}
          <Route path='/metatesting' element={<MetaTesting />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
