import { Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Header from './include/Header'
import { ToastContainer, toast } from 'react-toastify';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import robo from '../images/robo.webp'
import robo2 from '../images/robo2.webp'
import robo3 from '../images/blogr1.webp'
import robo4 from '../images/robust.webp'
import AOS from 'aos';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Navigate, useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Footer from './include/Footer';
import Axios from "../Axios";
import { consts } from '../constant';

const setHead = () => {
    document.title = "ThoreCoin/blog";

    // Update meta tags
    const metaDesc = document.querySelector('meta[name="description"]');
    if (metaDesc) {
      metaDesc.setAttribute('content','Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
      ogTitle.setAttribute('content',"ThoreCoin/blog");
    }

    const ogDesc = document.querySelector('meta[property="og:description"]');
    if (ogDesc) {
      ogDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const ogImage = document.querySelector('meta[property="og:image"]');
    if (ogImage) {
      ogImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
    }

    const ogUrl = document.querySelector('meta[property="og:url"]');
    if (ogUrl) {
      ogUrl.setAttribute('content', `${consts.MainUrl}/blog` || window.location.href);
    }

    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    if (twitterTitle) {
      twitterTitle.setAttribute('content', "ThoreCoin/blog");
    }

    const twitterDesc = document.querySelector('meta[name="twitter:description"]');
    if (twitterDesc) {
      twitterDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const twitterImage = document.querySelector('meta[name="twitter:image"]');
    if (twitterImage) {
      twitterImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
    }
  };

  setHead();

function Blog() {




    const [walletss, wallets] = useState("")

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(5)

    const navigate = useNavigate()
    const [blog, setBlog] = useState([])

    const solProviders = (data) => {
        // setSolProviders(data)
    }

    const eTHProviders = (data) => {
        // setETHProviders(data)
    }
    const [address, setAddress] = useState("")
    const [net, setNet] = useState("")
    useEffect(() => {
        if (net === "") {
            setNet(localStorage.getItem("Network"))
        } else {
            setNet("")
        }
    }, [])

    const getblogdetails = async () => {
        const response = await Axios.get('/getblog')


        var datas = response?.data?.result
        // const plainString = getText(response?.data?.result?.description?.editorData)
        for (let i = 0; i < datas.length; i++) {
            const data = getText(datas[i].description.editorData)
            datas[i].description.editorData1 = datas[i].description.editorData
            datas[i].description.editorData = data

        }
        setBlog(datas)
        // console.log(plainString , "vijay");


    }

    useEffect(() => {
        getblogdetails()
    }, [])

    function getText(html) {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }

    // const [blog, setBlog] = useState([
    //     { name: 'What is Thore Coin?', img: `${robo}`, desc: '  Thore is a digital asset that is backed by physical gold reserves. It is a form of cryptocurrency that is designed to provide stability and security through its connection to a tangible asset, gold. Launched in January 2018 and based in Switzerland, Germany, and Estonia, ThoreCoin offers a basket of cryptocurrencies and digital assets that anyone can invest in by purchasing ThoreCoin tokens, obviating the need to buy each asset separately. Features of the platform include the following:' },
    //     { name: 'Thore', img: `${robo2}`, desc: '  Thore is a digital asset that is backed by physical gold reserves. It is a form of cryptocurrency that is designed to provide stability and security through its connection to a tangible asset, gold.' },
    //     { name: 'Thore', img: `${robo4}`, desc: '  Thore is a digital asset that is backed by physical gold reserves. It is a form of cryptocurrency that is designed to provide stability and security through its connection to a tangible asset, gold.' },
    //     { name: 'Thore', img: `${robo2}`, desc: '  Thore is a digital asset that is backed by physical gold reserves. It is a form of cryptocurrency that is designed to provide stability and security through its connection to a tangible asset, gold.' },
    //     { name: 'Thore', img: `${robo3}`, desc: '  Thore is a digital asset that is backed by physical gold reserves. It is a form of cryptocurrency that is designed to provide stability and security through its connection to a tangible asset, gold.' },
    //     { name: 'Thore', img: `${robo}`, desc: '  Thore is a digital asset that is backed by physical gold reserves. It is a form of cryptocurrency that is designed to provide stability and security through its connection to a tangible asset, gold.' },
    //     { name: 'Thore', img: `${robo4}`, desc: '  Thore is a digital asset that is backed by physical gold reserves. It is a form of cryptocurrency that is designed to provide stability and security through its connection to a tangible asset, gold.' },
    //     { name: 'Thore', img: `${robo}`, desc: '  Thore is a digital asset that is backed by physical gold reserves. It is a form of cryptocurrency that is designed to provide stability and security through its connection to a tangible asset, gold.' },
    //     { name: 'Thore', img: `${robo3}`, desc: '  Thore is a digital asset that is backed by physical gold reserves. It is a form of cryptocurrency that is designed to provide stability and security through its connection to a tangible asset, gold.' },
    // ])

    AOS.init();

    const [flip, setFlip] = useState(false)
    return (
        <div className='blog'>
            <ToastContainer />
            <Header setToken={net} solProviders={solProviders} eTHProviders={eTHProviders} setAddress={setAddress} wallets={wallets} />
            <div
                className='banner-Thorecoin'
            >
                <Grid container spacing={0} className='thorecoin-banner' sx={{ justifyContent: 'center' }}>
                    {blog.length > 0 ? [...blog].slice((page - 1) * limit, page * limit)?.map((row, ind) => {
                        return (
                            <Grid item xs={11} sm={6} md={6} lg={4} xl={4}>
                                <div className='blog-card' data-aos={ind % 2 !== 0 ? "flip-right" : "flip-down"}>
                                    <Card sx={{ maxWidth: 400, boxShadow: '0px 27px 42px 0px #3959ff4d', borderRadius: '15px' }} key={ind} className='card-trans' onClick={() => { navigate(`/bloginner/${row.slug_url}`, { state: row }) }} >
                                        <CardMedia
                                            sx={{ height: 210 }}
                                            image={row?.image_url}
                                            title="green iguana"
                                            className='card-mda'
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div" className='blog-c-title'>
                                                {row?.title.length > 50 ? `${row?.title.slice(0, 50)}...` : row?.title}
                                            </Typography>
                                            <Typography variant="body2" className='blog-c-para'>
                                                {row?.description?.editorData.length > 120 ? `${row?.description?.editorData.slice(0, 120)} ...` : row?.description?.editorData}

                                            </Typography>
                                        </CardContent>

                                        <div className='blog-c-btn'>
                                            {/* <Button onClick={() => { navigate(`/bloginner/${row.slug_url}`, { state: row }) }} size="small" variant='contained'>Read More <ArrowRightAltIcon /></Button> */}
                                            <Button class="button" onClick={() => { navigate(`/bloginner/${row.slug_url}`, { state: row }) }} size="small" variant='contained'>
                                                <span class="button-content">Read More <ArrowRightAltIcon /> </span>
                                            </Button>


                                        </div>



                                    </Card>
                                </div>

                            </Grid>
                        )
                    }) :
                        <></>}

                </Grid >
                {page ?
                    <Stack spacing={2} sx={{ margin: '50px 0px' }} className='pageniation'>
                        <Pagination
                            count={Math.ceil(blog?.length / limit)}
                            page={page}
                            onChange={(event, value) => {
                                setPage(value)
                            }}
                            color="primary" />
                    </Stack> :
                    <></>
                }
                <Footer />
            </div >

        </div >
    )
}

export default Blog
