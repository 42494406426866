import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import Header from './include/Header'
import Footer from './include/Footer'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import wreath from '../images/wreath.webp'
import peresaleicon1 from '../images/peresale-strucuture-icon-1.webp'
import peresaleicon2 from '../images/peresale-strucuture-icon-2.webp'
import peresalestrucutureicon3 from '../images/peresale-strucuture-icon-4.webp'

import peresaleicon3 from '../images/dudu-bubu-bear-and-panda.gif'
import presaletopicon from '../images/presale-top-icon.webp'
import FundAllocation from '../images/FundAllocation-1.webp'
import FAprocess from '../images/fund-allocation-step-img.webp'
import { Link } from 'react-router-dom'

import bp1 from '../images/bp-1.webp'
import bp2 from '../images/bp-2.webp'
import bp3 from '../images/bp-3.webp'
import bp4 from '../images/bp-4.webp'
import bp5 from '../images/bp-5.webp'
import bp6 from '../images/bp-6.webp'
import bp7 from '../images/bp-7.webp'
import loader from '../images/loader_new.gif'
import Backdrop from '@mui/material/Backdrop';
import accordplus from '../images/accord-plus.webp'
import accordminus from '../images/accord-minus.webp'
import bannervideo from '../images/banner-video.mp4'
import bubuduvideo from '../images/bubuduvideo.mp4'
import whobuduboard from '../images/who-budu-board.webp'
import buduwhotop from '../images/budu-who-top.webp'
import bannerimg from '../images/bannerimg.webp'
import coincodex from '../images/coincodex-logo.webp'
import eth from '../images/eth.webp'
import sol from '../images/SOL.webp'
import usdt from '../images/usdt.webp'
import card from '../images/card.webp'
import buducoin from '../images/buducoin.webp'
import whoisbudu from '../images/who-is-budu.webp'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { consts } from '../constant';
import Abi from '../Abi.json'
import Web3 from 'web3'
import * as web3 from "@solana/web3.js";
import * as buffer from "buffer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menu from '@mui/material/Menu';
import Axios from "../Axios";

const setHead = () => {
  document.title = "ThoreCoin/presale";

  // Update meta tags
  const metaDesc = document.querySelector('meta[name="description"]');
  if (metaDesc) {
    metaDesc.setAttribute('content','Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
  }

  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute('content',"ThoreCoin/presale");
  }

  const ogDesc = document.querySelector('meta[property="og:description"]');
  if (ogDesc) {
    ogDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
  }

  const ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute('content', `${consts.MainUrl}/presale` || window.location.href);
  }

  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute('content', "ThoreCoin/presale");
  }

  const twitterDesc = document.querySelector('meta[name="twitter:description"]');
  if (twitterDesc) {
    twitterDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
  }

  const twitterImage = document.querySelector('meta[name="twitter:image"]');
  if (twitterImage) {
    twitterImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
  }
};

setHead();



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  sidebarcls: {
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    top: '0px',
    padding: '0px !important',
  }
  // headercls: {
  //     background: '#131a26 !important',
  //     borderRadius: '0px !important',
  //     boxShadow:'none !important',
  //     padding:'20px !important',
  //     '& form':{
  //       padding:'0px !important',
  //       '@media (max-width: 767.98px)' : {
  //         width: '100%',
  //       },
  //       '& button': {
  //         background: '#25DEB0',
  //         borderRadius: '0px 5px 5px 0px'
  //       }
  //     },
  //     '@media (max-width: 767.98px)' : {
  //       padding: '20px !important',
  //     },
  // }
});



const Presale = () => {
  document.title = "ThoreCoin/presale";
  const classes = useStyles();
  window.Buffer = buffer.Buffer;

  



  const [selected, setSelected] = useState(0)
  // const[selectedTab, setSelectedTab] = useState(0)

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }

  const data = [
    { title: 'How can I buy Bubu Dudu Token using Thorecoin?', answer: 'To purchase Bubu Dudu Token with Thorecoin, you will need to use a compatible cryptocurrency exchange that lists both Bubu Dudu Token and Thorecoin. Follow these steps:Register and verify your account on the exchange.Deposit Thorecoin into your exchange wallet.Navigate to the exchange section and select the THORE/Bubu Dudu trading pair.Enter the amount of Bubu Dudu Token you want to buy and execute the trade.' },
    { title: 'What are the listing prospects for Bubu Dudu Token?', answer: 'Bubu Dudu Token is in the process of being listed on several prominent cryptocurrency exchanges. The goal is to enhance accessibility and liquidity for the token across major markets. Stay updated by following official announcements on our website and social media channels for the latest listing news.' },
    { title: 'Where should I store my Bubu Dudu Token—Phantom Wallet or Trust Wallet?', answer: 'Bubu Dudu Token can be safely stored in both Phantom Wallet and Trust Wallet, as they support the token standards used by Bubu Dudu Token. Your choice depends on your preference for interface, additional features, and overall ease of use:Phantom Wallet: Often preferred for its user-friendly interface and additional features like staking.Trust Wallet: Known for its robust security measures and multi-currency support.' },
    { title: 'Is there a plan to list Bubu Dudu Token in the Indian market?', answer: 'Yes, there are plans to list Bubu Dudu Token on Indian cryptocurrency exchanges. This initiative aims to cater to the growing demand for digital assets in India and integrate with local payment methods, making it easier for Indian investors to access and trade Bubu Dudu Token.' },
    { title: 'What is the future of Thore Network’s first meme coin?', answer: 'As the first meme coin of Thore Network, Bubu Dudu Token is designed to capture and engage a broad community with its unique branding and utility. The future plans include expanding its ecosystem, enhancing its use cases, and engaging in community-driven projects that promote growth and increase token utility. Additionally, collaborations with influencers and strategic partnerships are being explored to boost its visibility and adoption.' }
  ]

  // const targetDate = new Date("2024-12-31T23:59:59");
  // const targetDate = new Date("2024-08-02T06:03:26");
  const [targetDate, setTargetDate] = useState()
  const [tokenPrice, setTokenPrice] = useState()
  const [USDT1, setUSDT1] = useState()
  const [USDT2, setUSDT2] = useState()

  // console.log(targetDate?.split('.')[0] , 'targetdate');
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    if (difference <= 0) {
      return {};
    }
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [targetDate]);


  const [coinVal, setCoinVal] = useState('SOL')


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, val) => {
    setCoinVal(val);
  };

  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  //   setOpen(false);
  // };






  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'spring-popper' : undefined;

  const [token, setTokens] = useState()
  const [solProvider, setSolProviders] = useState()
  const [ethProvider, setETHProviders] = useState()
  const [pay, setPay] = useState(0)
  const [receive, setReceive] = useState(0)
  const [address, setAddress] = useState("")

  // const [priceToken, setPriceToken] = useState(0.00025)
  const [bonus, setBonus] = useState(20)
  const [minimum, setMinimum] = useState(100)
  const [maximum, setMaximum] = useState(10000)
  const [totalAlloc, setTotalAlloc] = useState(2.76)
  const [conversionRate, setConversionRate] = useState(4000)

  const [payError, setPayError] = useState("")
  const [err, setErr] = useState(false)
  const [walletss, wallets] = useState("")
  const [ethAddress, setEthAddress] = useState('')
  const [solAddress, setSolAddress] = useState('')
  const [presaleDetails, setPresaleDetails] = useState()
  const [obj1, setObj1] = useState({})
  const [obj2, setObj2] = useState({})
  const [obj3, setObj3] = useState({})


  const solProviders = (data) => {
    setSolProviders(data)
  }

  const eTHProviders = (data) => {
    setETHProviders(data)
  }

  const [load, setLoad] = useState(false)
  const handelloader = () => {
    setLoad(true)

    const timer = setTimeout(() => {
      setLoad(false)
    }, 3000);

    return () => clearTimeout(timer)
  }




  function usdtToBubu(usdt) {
    if (coinVal == "ETH") {
      setPay(usdt)
      if (minimum <= usdt && maximum >= usdt) {
        setPayError("")
        var amount = usdt * conversionRate
        if (bonus != 0) {
          var percentage = (amount * bonus) / 100
          setReceive(parseFloat(Number(amount) + percentage).toFixed(0))
        } else {
          setReceive(parseFloat(Number(amount)).toFixed(0))
        }
      } else {
        setReceive(Number(0))
        setPayError("Please Enter Correct Value")
      }
    }
    else {
      setPay(usdt)
      if (0.75 <= usdt && 75.5 >= usdt) {
        setPayError("")
        var sol = usdt * 132.37
        var amount = sol * conversionRate
        if (bonus != 0) {
          var percentage = (amount * bonus) / 100
          setReceive(parseFloat(Number(amount) + percentage).toFixed(0))
        } else {
          setReceive(parseFloat(Number(amount)).toFixed(0))
        }
      } else {
        setReceive(Number(0))
        setPayError("Please Enter Correct Value")
      }
    }

  }



  const airDropSol = async (connection, publicKey) => {
    try {
      const airdropSignature = await connection.requestAirdrop(
        publicKey,
        web3.LAMPORTS_PER_SOL
      );

      const latestBlockHash = await connection.getLatestBlockhash();

      // Confirming that the airdrop went through
      await connection.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature: airdropSignature
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getAdminAddress = async () => {
    try {
      const response = await Axios.post('/getAdminAddrbyUser')
      // console.log(response.data.result, 'response');
      if (response.data.success) {
        for (let i = 0; i < response.data.result.length; i++) {
          if (response.data.result[i].network == 'ETH') {
            setEthAddress(response.data.result[i].address)
          }
          else if (response.data.result[i].network == 'SOL') {
            setSolAddress(response.data.result[i].address)
          }
        }
      }
    } catch (error) {
      console.log(error, 'err');
    }
  }

  // useEffect(() => {
  //   getAdminAddress()
  // }, [])

  // console.log(ethAddress, solAddress, 'address');

  const Buy = async () => {
    try {
      if (payError) {
        toast.error(payError)
        setErr(true)
      } else if (pay === 0) {
        toast.error("Value should note be zero")
        // setPayError("Value should note be zero")
      } else if (payError != "") {
        toast.error(payError)
      }
      else {
        if (coinVal == "ETH") {
          setLoad(true)
          var providers = new Web3(ethProvider)
          const contractInstance = new providers.eth.Contract(Abi, consts.eth_usdt_contract_address)
          var decimal = await contractInstance.methods.decimals().call()
          var amou = Number(pay) * (10 ** decimal)
          // const sendtrans = await contractInstance.methods.transfer(consts.eth_admin_address, amou).send({
          //   from: address
          // })
          const sendtrans = await contractInstance.methods.transfer(ethAddress, amou).send({
            from: address
          })
          if (sendtrans) {
            let data = {};
            data.ethAddress = sendtrans?.from;
            data.solAddress = localStorage.getItem("SOLADD");
            data.transaction = sendtrans.transactionHash;
            data.presaleAmount = pay;
            data.transferby = "ETH/USDT";
            data.bubududuAmount = receive;

            const response = await Axios.post(`/presale`, { data })
            if (response) {
              setPay(0)
              setReceive(0)
              toast.success("Transaction succeed")
              setLoad(false)
            } else {
              toast.success("Reciept Error")
              setLoad(false)
            }
          }
        } else {
          setLoad(true)
          const provider = window.solana;
          const response = await provider.connect();
          const phantomProvider = provider;
          const pubKey = await phantomProvider.publicKey;

          var connection = new web3.Connection(
            "https://few-wispy-feather.solana-mainnet.quiknode.pro/f75c4da116304930f4d50236c387cfacdda128a7/", "confirmed"
          );


          var recieverWallet = new web3.PublicKey(
            consts.sol_admin_address
          );
          // Fetch and log the wallet balance
          const balance = await connection.getBalance(pubKey);
          // airDropSol(connection, pubKey);
          // let transaction = new web3.Transaction().add(
          //   web3.SystemProgram.transfer({
          //     fromPubkey: pubKey,
          //     toPubkey: consts.sol_admin_address,
          //     lamports: pay * web3.LAMPORTS_PER_SOL
          //   })
          // );
          let transaction = new web3.Transaction().add(
            web3.SystemProgram.transfer({
              fromPubkey: pubKey,
              toPubkey: solAddress,
              lamports: pay * web3.LAMPORTS_PER_SOL
            })
          );
          transaction.feePayer = pubKey;

          let blockhashObj = await connection.getRecentBlockhash();
          transaction.recentBlockhash = blockhashObj.blockhash;

          let signed = "";
          try {
            signed = await phantomProvider.signTransaction(transaction);
          } catch (err) {
            setLoad(false)
            toast.error("Transaction Reverted")
          }

          let txid = "";
          try {
            txid = await connection.sendRawTransaction(signed.serialize());
          } catch (ex) {
            setLoad(false)
            console.log("ex", ex);
          }

          try {

            await connection.confirmTransaction(txid);
            const transactions = await connection.getTransaction(txid);
            if (transaction) {
              let data = {};
              data.ethAddress = localStorage.getItem("ETHADD");
              data.solAddress = pubKey?.toString();
              data.transaction = txid;
              data.presaleAmount = pay;
              data.transferby = "SOL";
              data.bubududuAmount = receive;

              const response = await Axios.post(`/presale`, data)
              if (response) {
                setPay(0)
                setReceive(0)
                toast.success("Transaction succeed")
                setLoad(false)
              } else {
                toast.success("Reciept Error")
                setLoad(false)
              }
            } else {
              toast.error("Transaction Hash ERROR")
            }

          } catch (err) {
            setLoad(false)
            console.log("err", err);
          }


        }
      }
    } catch (error) {
      setLoad(false)
      console.log("🚀 ~ Buy ~ error:", error)
      toast.error("Check the Balance And Network Connection")
    }
  }

  const getTimeLeft = async () => {
    try {
      const response = await Axios.post('/getPublicUserPresale', { type: 'admin' })
      console.log(response, 'response');
      if (response.data.success && response.data.result.is_start) {
        setTargetDate(response.data.result.is_startdate)
        setTokenPrice(response.data.result.price_per_token)
        setUSDT1(response.data.result.USDT_Raised1)
        setUSDT2(response.data.result.USDT_Raised2)
      }
    } catch (error) {
      console.log(error, 'err');
    }
  }

  const getPresaleDetails = async () => {
    try {
      const response = await Axios.post('/getPublicPresalebyUser', { type: 'admin' }, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (response.data.success) {
        let data = response.data.result
        setObj1(data[0])
        setObj2(data[1])
        setObj3(data[2])
      }
    } catch (error) {
      console.log(error, 'err');
    }
  }

  useEffect(() => {
    if (token === undefined) {
      setTokens(localStorage.getItem("Network"))
    }
    getTimeLeft()
    getPresaleDetails()
  }, [])

  return (
    <div className='landing-page-main'>
      <ToastContainer />

      <Header setToken={token} solProviders={solProviders} eTHProviders={eTHProviders} setAddress={setAddress} wallets={wallets} />
      <div className='banner-section-landing'>
        <video src={bannervideo} autoPlay="autoplay" muted loop></video>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Item className={classes.sidebarcls} id="banner-left-part">
                <motion.div className='banner-text'
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                  viewport={{ once: true }}
                >
                  <label>Powered by: Thorecoin</label>
                  <h1>BubuDuDu</h1>
                  <span>Rev Up Your Earnings</span>
                </motion.div>
                <div className='budu-flex'>
                  <div className='banner-logo'><Link to='https://coincodex.com/ico/bubududu-token/' target="_blank">
                    <img src={coincodex} alt="coincodex" /></Link>
                  </div>

                  <div className='banner-img'><img src={bannerimg} alt="bannerimg" /></div>

                </div>


              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
              <Item className={classes.sidebarcls}>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Item className={classes.sidebarcls}>

                <div className='presale-form presale-form-for-presale'>
                  <div className='peresaleicon3'><img src={peresaleicon3} alt="peresaleicon3" /></div>
                  <legend className="presale-form-head">Next Price Increase In</legend>
                  {Object.keys(timeLeft).length === 0 ? (
                    <span>Time's up!</span>
                  ) : (
                    <div className='dhms-outer'>
                      {/* {console.log(typeof timeLeft.days, 'days')} */}
                      <div className='dhms'><label>Days</label><span>{isNaN(timeLeft.days) ? 0 : timeLeft.days}</span></div>
                      <div className='dhms'><label>Hours</label><span>{isNaN(timeLeft.hours) ? 0 : timeLeft.hours}</span></div>
                      <div className='dhms'><label>Minutes</label><span>{isNaN(timeLeft.minutes) ? 0 : timeLeft.minutes}</span></div>
                      <div className='dhms'><label>Seconds</label><span>{isNaN(timeLeft.seconds) ? 0 : timeLeft.seconds}</span></div>
                    </div>
                  )}
                  <p className='font-caps'><label>USDT Raised:</label>
                    {/* <span>$5,391,595.54 / $5,607,253</span> */}
                    {/* <span>${USDT1 && USDT2 ? `${USDT1} / ${USDT2}` : '0 / 0'}</span> */}
                    <span>${USDT1 ? USDT1 : 0} / ${USDT2 ? USDT2 : 0}</span>

                  </p>
                  <div className='price-increase-time'>
                    <span className='track-range'></span>
                    <label>Until price increase</label>
                    <span className='range-length' style={{ width: '80%' }}></span>
                  </div>
                  {receive ? <p className='font-caps'>Your purchased BUBUDUDU= {receive} </p> :
                    <p className='font-caps'>Your purchased BUDU= 0 </p>
                  }
                  {coinVal === "ETH" ? <p className='font-caps'>1 USDT = 4000 BUBUDUDU </p>
                    :
                    <p className='font-caps'>1 SOL = 529000 BUBUDUDU </p>
                  }
                  <span className='span-rate'><span className='span-rate-inner font-caps'>1 BUDU = ${tokenPrice}</span></span>
                  <Stack className="presale-coin-selected-btns presale-coin-selected-btns-three-btns">
                    <div className='presale-plan-in-button'><Button variant="contained" className='active-btn' onClick={handleClick2}><img src={coinVal === 'ETH' ? eth : sol} alt="eth" />{coinVal === 'ETH' ? 'ETH' : "SOL"}</Button></div>
                    {coinVal === "ETH" ? <div className='presale-plan-in-button'><Button variant="contained"><img src={usdt} alt="usdt" />USDT</Button></div>
                      :
                      <></>
                    }
                    <div className='presale-plan-in-button card-option-btn'><a href={`mailto:info@thorenetwork.com?subject=Regarding BUBUDUDU Purchase`} variant="contained"><img src={card} alt="card" />card</a></div>
                  </Stack>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',

                    }}
                    className="popper-eth"
                  >
                    <div onClick={((event) => { handleClick(event, 'SOL'); handleClose(); setTokens("SOL") })}>
                      <img src={sol} alt="eth" />SOL
                    </div>
                    <div onClick={((event) => { handleClick(event, 'ETH'); handleClose(); setTokens("ETH") })}>
                      <img src={eth} alt="eth" />ETH
                    </div>

                  </Menu>

                  <Stack className="presale-coin-selected-btns text-field-box">
                    <div className='text-field-box-inner'>
                      <div className='flex-label'><label>{coinVal} you pay</label><span>Max</span></div>
                      <div className='text-field-box-inner-inner'>
                        <TextField id="outlined-basic" value={pay} variant="outlined" onChange={(e) => { usdtToBubu(e.target.value) }} />
                        <img src={coinVal === "ETH" ? eth : sol} alt="eth" />
                        {coinVal === "ETH" ? <div className='flex-label value'><label>MIN-USDT:100</label>   <label>MAX-USDT:10000</label></div>
                          :
                          <div className='flex-label value'><label>MIN-SOL:0.75</label>   <label>MAX-SOL:75</label></div>
                        }
                      </div>
                    </div>
                    <div className='text-field-box-inner'>
                      <div className='flex-label'><label>BUBUDUDU you receive</label></div>
                      <div className='text-field-box-inner-inner'>
                        <TextField id="outlined-basic" value={receive} variant="outlined" />
                        <img src={buducoin} alt="buducoin" />
                      </div >
                    </div >
                  </Stack >
                  <Stack className="presale-coin-selected-btns equal-width">
                    {!address ? <div className='presale-plan-in-button'><Button variant="contained" style={{ padding: '10px 0px' }} onClick={() => setTokens(coinVal)}>Connect Wallet</Button></div>
                      :
                      <div className='presale-plan-in-button' onClick={() => { Buy(); getAdminAddress() }}><Button variant="contained"><img src={coinVal === "ETH" ? eth : sol} alt="bnb" style={{ width: '22px' }} />Buy with {coinVal}</Button></div>}
                  </Stack >
                  <legend className='presale-form-footer-powered'>Powered by: Thorecoin</legend>
                </div >
              </Item >
            </Grid >
            <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
              <Item className={classes.sidebarcls}>
              </Item>
            </Grid>
          </Grid >
        </Box >
      </div >


      <Backdrop
        sx={{ color: '#16161ad6', zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: 'blur(2px)' }}
        open={load}
      >
        <img src={loader} alt="bp1" />
      </Backdrop>

      <div className='presale-section who-is-bubudu'>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.sidebarcls}>
                <h2 className='landing-page-heading-2'>Who Is Bubu Dudu?</h2>
                <div className='wreath who-is-bubudu-wreath'><img src={wreath} alt="wreath" /></div>
                <div className='buduwhotop'><img src={buduwhotop} alt="buduwhotop" /></div>
                <div className='who-is-bubu-dudu'>
                  <div className='who-is-budu-img'>
                    <img src={whoisbudu} alt="who-is-budu" />
                    <img className='whobuduboard' src={whobuduboard} alt="who-is-budu" />
                  </div>
                  <div className='who-is-budu-video'>
                    <video src={bubuduvideo} autoPlay="autoplay" muted loop></video>
                  </div>
                </div>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>

      <motion.div className='presale-section'
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        transition={{ duration: 0.6, delay: 0.3 }}
        viewport={{ once: true }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.sidebarcls}>
                <h2 className='landing-page-heading-2'>Presale/Airdrop starting june 30, 2024</h2>
                <p className='presale-section-para-1'>Bubu DuDu, Thore Network's first meme coin, powered by Thorecoin! This unique
                  project presents a 1 OOX opportunity for our community. To add to the excitement,
                  we are incorporating the power of Solana into our ecosystem, ensuring fast and
                  efficient transactions.</p>
                <p className='presale-section-para-2'>Don't miss out on this incredible opportunity to be part of the next big thing in the crypto world. Mark your calendars and get ready to join the Bubu DuDu revolution!</p>
                <div className='wreath'><img src={wreath} alt="wreath" /></div>
                <p className='presale-section-para-3'>Draft Presale plan for BubuDuDu Token Ticker BuDu based on Solana Blockchain Powered by Thorecoin total supply 18.4 Billion tokens Launch price 0.0003 usd</p>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </motion.div>

      <div className='presale-plan'>
        <motion.div className='presale-plan-inner'
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          viewport={{ once: true }}
        >
          <div className='presale-plan-in'><legend>Presale Plan for BubuDuDu Token (Ticker: BuDu)</legend></div>
          <div className='presale-plan-in'><legend>Blockchain:</legend><legend> Solana</legend></div>
          <div className='presale-plan-in'><legend>Powered by:</legend><legend> Thorecoin</legend></div>
          <div className='presale-plan-in'><legend>Total Supply:</legend><legend> 18.4 Billion Tokens</legend></div>
          <div className='presale-plan-in'><legend>Launch Price:</legend><legend> $0.0003 USD</legend></div>
          <div className='presale-plan-in presale-plan-in-button'><Button variant="contained">Buy now</Button></div>
        </motion.div>
      </div>


      <div className='presale-structure'>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} order={{ xs: 3, sm: 3, lg: 1, xl: 1 }}>
              <Item className={classes.sidebarcls}>
                <legend className='phase-even-left-align'>Phase 1: Early Bird Round</legend>
                <motion.div className='card-landing card-common'
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                  viewport={{ once: true }}
                >
                  <div className='presale-steps-icon peresaleicon1'><img src={peresaleicon1} alt="peresaleicon1" /></div>
                  <p><label>Token Allocation:</label> <span>{obj1.token_allocation} BuDu ({obj1.token_allocation_percentage}% of total supply)</span></p>
                  <p><label>Price per Token:</label> <span>${obj1.price_per_token} USD</span></p>
                  <p><label>Bonus: </label>{obj1.bonus > 0 ? <span>{bonus}% extra tokens for early investors</span> : <span>No Bonus</span>}</p>
                  <p><label>Duration:</label> <span>{obj1.Duration} weeks</span></p>
                  <p><label>Minimum Investment:</label> <span>${obj1.Minimum_Investment} USD</span></p>
                  <p><label>Maximum Investment:</label> {obj1.Maximum_Investment == 0 ? <span> No Limit</span> : <span>${obj1.Maximum_Investment} USD</span>}</p>
                  {
                    obj1.enable ? "" : <p style={{ textAlign: "center", fontWeight: "bold" }}><span>Coming Soon</span></p>
                  }
                  {/* <div className='presale-steps-icon peresaleicon1'><img src={peresaleicon1} alt="peresaleicon1" /></div>
                  <p><label>Token Allocation:</label> <span>2.76 Billion BuDu (15% of total supply)</span></p>
                  <p><label>Price per Token:</label> <span>$0.00025 USD</span></p>
                  <p><label>Bonus: </label><span>20% extra tokens for early investors</span></p>
                  <p><label>Duration:</label> <span>2 weeks</span></p>
                  <p><label>Minimum Investment:</label> <span>$100 USD</span></p>
                  <p><label>Maximum Investment:</label> <span>$10,000 USD</span></p> */}
                  {/* <p><label>Phase 2:</label> <span>Strategic Round</span></p> */}
                </motion.div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2} order={{ xs: 2, sm: 2, lg: 2, xl: 2 }}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} order={{ xs: 1, sm: 1, lg: 3, xl: 3 }}>
              <Item className={classes.sidebarcls}>
                <h3 className='presale-structure-3'>
                  Presale Structure
                  <img src={presaletopicon} alt='img' />
                </h3>
                <div className='wreath wreath-top-presale rotate-left'><img src={wreath} alt="wreath" /></div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} order={{ xs: 4, sm: 4, lg: 4, xl: 4 }}>
              <Item className={classes.sidebarcls}>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2} order={{ xs: 5, sm: 5, lg: 5, xl: 5 }}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} order={{ xs: 6, sm: 6, lg: 6, xl: 6 }}>
              <Item className={classes.sidebarcls}>
                <legend className='phase-even-right'>Phase 2: Strategic Round</legend>
                <motion.div className='card-landing card-common'
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                  viewport={{ once: true }}
                >
                  <div className='presale-steps-icon peresaleicon2'><img src={peresaleicon2} alt="peresaleicon2" /></div>
                  <p><label>Token Allocation:</label> <span>{obj2.token_allocation} BuDu ({obj2.token_allocation_percentage}% of total supply)</span></p>
                  <p><label>Price per Token:</label> <span>${obj2.price_per_token} USD</span></p>
                  <p><label>Bonus: </label>{obj2.bonus > 0 ? <span>{obj2.bonus}% extra tokens</span> : <span>No bonus</span>}</p>
                  <p><label>Duration:</label> <span>{obj2.Duration} weeks</span></p>
                  <p><label>Minimum Investment:</label> <span>${obj2.Minimum_Investment} USD</span></p>
                  <p><label>Maximum Investment:</label>{obj2.Maximum_Investment == 0 ? <span>No Limit</span> : <span>${obj2.Maximum_Investment} USD</span>}</p>
                  {
                    obj2.enable ? "" : <p style={{ textAlign: "center", fontWeight: "bold" }}><span>Coming Soon</span></p>
                  }
                  {/* <div className='presale-steps-icon peresaleicon2'><img src={peresaleicon2} alt="peresaleicon2" /></div>
                  <p><label>Token Allocation:</label> <span>3.68 Billion BuDu (20% of total supply)</span></p>
                  <p><label>Price per Token:</label> <span>$0.000275 USD</span></p>
                  <p><label>Bonus: </label><span>10% extra tokens</span></p>
                  <p><label>Duration:</label> <span>2 weeks</span></p>
                  <p><label>Minimum Investment:</label> <span>$100 USD</span></p>
                  <p><label>Maximum Investment:</label> <span>$50,000 USD</span></p> */}
                </motion.div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} order={{ xs: 7, sm: 7, lg: 7, xl: 7 }}>
              <Item className={classes.sidebarcls}>
                <legend className='phase-even-left-align'>Phase 3: Public Presale</legend>
                <motion.div className='card-landing card-common'
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                  viewport={{ once: true }}
                >
                  <div className='presale-steps-icon peresaleicon3'><img src={peresalestrucutureicon3} alt="peresaleicon3" /></div>
                  <p><label>Token Allocation:</label> <span>{obj3.token_allocation}BuDu ({obj3.token_allocation_percentage}% of total supply)</span></p>
                  <p><label>Price per Token:</label> <span>${obj3.price_per_token} USD</span></p>
                  <p><label>Bonus: </label>{obj3.bonus > 0 ? <span>{obj3.bonus}% extra tokens</span> : <span>No bonus</span>}</p>
                  <p><label>Duration:</label> <span>{obj3.Duration} weeks</span></p>
                  <p><label>Minimum Investment:</label> <span>${obj3.Minimum_Investment} USD</span></p>
                  <p><label>Maximum Investment:</label> {obj3.Maximum_Investment == 0 ? <span>No limit</span> : <span>${obj3.Maximum_Investment} USD</span>}</p>
                  {
                    obj3.enable ? "" : <p style={{ textAlign: "center", fontWeight: "bold" }}><span>Coming Soon</span></p>
                  }
                  {/* <div className='presale-steps-icon peresaleicon3'><img src={peresalestrucutureicon3} alt="peresaleicon3" /></div>
                  <p><label>Token Allocation:</label> <span>5.52 Billion BuDu (30% of total supply)</span></p>
                  <p><label>Price per Token:</label> <span>$0.0003 USD</span></p>
                  <p><label>Bonus: </label><span>No bonus</span></p>
                  <p><label>Duration:</label> <span>4 weeks</span></p>
                  <p><label>Minimum Investment:</label> <span>$50 USD</span></p>
                  <p><label>Maximum Investment:</label> <span>No limit</span></p> */}
                </motion.div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2} order={{ xs: 8, sm: 8, lg: 8, xl: 8 }}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} order={{ xs: 9, sm: 9, lg: 9, xl: 9 }}>
              <Item className={classes.sidebarcls}>
                <div className='wreath wreath-top-presale rotate-right'><img src={wreath} alt="wreath" /></div>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>


      <div className='presale-structure fund-allocation'>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0}>

            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <Item className={classes.sidebarcls}>
                <h3 className='presale-structure-3'>
                  Fund Allocation</h3>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <Item className={classes.sidebarcls}>
                <div className='FundAllocation'><img src={FundAllocation} alt="FundAllocation" /></div>
              </Item>
            </Grid>

          </Grid>
          <div className='fund-allocation-process'>
            <div className='fund-allocation-process-odd-step'>
              <img src={FAprocess} alt="FAprocess" />
              <legend>Marketing and Promotion: 25%</legend>
            </div>
            <div className='fund-allocation-process-even-step'>
              <img src={FAprocess} alt="FAprocess" />
              <legend>Product Development: 40%</legend>
            </div>
            <div className='fund-allocation-process-odd-step'>
              <img src={FAprocess} alt="FAprocess" />
              <legend>Liquidity Pool: 20%</legend>
            </div>
            <div className='fund-allocation-process-even-step'>
              <img src={FAprocess} alt="FAprocess" />
              <legend>Operational Expenses: 10%</legend>
            </div>
            <div className='fund-allocation-process-odd-step'>
              <img src={FAprocess} alt="FAprocess" />
              <legend>Legal and Regulatory Compliance: 5%</legend>
            </div>
          </div>
        </Box>
      </div>

      <div className='seven-companies'>
        <p>Featured In</p>
        <div className='seven-companies-inner'>
          <div><Link to='https://www.binance.com/en-IN/price/thorecoin'><img src={bp1} alt="bp1" /></Link></div>
          <div><Link to='https://finance.yahoo.com/company/thore-network?h=eyJlIjoidGhvcmUtbmV0d29yayIsIm4iOiJUaG9yZUNvaW4ifQ==&.tsrc=fin-srch
'><img src={bp2} alt="bp2" /></Link></div>
          <div><Link to='https://theprint.in/ani-press-releases/thore-network-set-to-introduce-thorecoin-2-0-with-ai-capabilities/1639093/
'><img src={bp3} alt="bp3" /></Link></div>
          <div><Link to='https://www.marketwatch.com/investing/private-company/thore-network?mod=search_companies'><img src={bp4} alt="bp4" /></Link></div>
          <div><Link to='https://equityzen.com/company/thorenetwork/'><img src={bp5} alt="bp5" /></Link></div>
          <div><Link to='https://alphagrowth.io/thorecoin'><img src={bp6} alt="bp6" /></Link></div>
          <div><Link to='https://www.crunchbase.com/organization/thore-network'><img src={bp7} alt="bp7" /></Link></div>
        </div>
      </div>

      <div className='faq-section'>
        <h4>Frequently Asked Questions</h4>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            {data?.map((data, i) =>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={i} className="accord-title-cont">
                <Item className={classes.sidebarcls}>
                  <div className="accord-title" onClick={() => toggle(i)}>{data.title}
                    {/* <i className={selected == i ? "fa-solid fa-minus" : "fa-solid fa-plus"}></i> */}
                    <div className='accordminus-accordplus'><img src={selected === i ? accordminus : accordplus} alt='img' /></div>
                  </div>
                  <div className={selected === i ? "accord-content show" : "accord-content"}>{data.answer}</div>
                </Item>
              </Grid>
            )
            }
          </Grid>
        </Box>
      </div>

      <Footer />
    </div >
  )
}

export default Presale
