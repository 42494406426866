
import Header from './include/Header'
import { Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import aiblockchain from '../images/aiblockchain.webp'
import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
// import { Helmet } from "react-helmet";
import Footer from './include/Footer';
import { consts } from '../constant';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Axios from "../Axios";

function BlogInner() {
    const StyledDiv = styled.div`
    h2{
   font-family: "Inter", sans-serif !important;
   text-align: left;
   margin-bottom: 25px;
   font-size: 48px !important;
 }
 h3 {
   font-size: 33px !important;
     font-family: "Inter", sans-serif !important;
   text-align: left;
   margin-bottom: 25px;
 }
 
  h4 {
   font-size: 27px !important;
     font-family: "Inter", sans-serif !important;
   text-align: left;
   margin-bottom: 25px;
 }
 
  h5 {
   font-size: 22px !important;
     font-family: "Inter", sans-serif !important;
   text-align: left;
   margin-bottom: 25px;
 }
 
 h6 {
   font-size: 18px !important;
     font-family: "Inter", sans-serif !important;
   text-align: left;
   margin-bottom: 25px;
 }
 
  ol li {
   font-family: "Inter", sans-serif !important;
   font-size: 18px;
   line-height: 30px;
 }
 
 ol li {
   margin: 20px 0px;
 }
    ul li {
   font-family: "Inter", sans-serif !important;
   font-size: 18px;
   line-height: 30px;
 }
 
 ul li {
   margin: 20px 0px;
 }
 
 p{
 font-family: "Inter", sans-serif !important;
   text-align: left;
   margin-bottom: 25px;
   line-height:25px;
   font-size: 18px !important;
 }
 
 a{
 color:#000;
 text-decoration:underline:
 font-family: "Inter", sans-serif !important;
   text-align: left;
   margin-bottom: 25px;
   line-height:25px;
   font-size: 16px !important;
 }
 a:hover{
 color:rgba(57, 89, 255, 1);
 
 }
 
 strong{
 font-weight:800
 }
 
 ol li {
   font-family: "Inter", sans-serif !important;
   font-size: 18px;
   line-height: 30px;
 }
 
 ol li {
   margin: 20px 0px;
 }
 
   `;


    const [walletss, wallets] = useState("")
    const [apdata, setApdata] = useState("")
    // const [ path , setPath ] = useState("")
    const solProviders = (data) => {
        // setSolProviders(data)
    }

    const data = useLocation()

    // let result = data.state
    let pa = data.pathname
    let pat = pa.split("/")
    let path = pat[2]

    const eTHProviders = (data) => {
        // setETHProviders(data)
    }
    const [address, setAddress] = useState("")
    const [net, setNet] = useState("")


    const navigate = useNavigate()
    useEffect(() => {
        if (net === "") {
            setNet(localStorage.getItem("Network"))
        } else {
            setNet("")
        }
    }, [])

    useEffect(() => {
        getbyname()
    }, [path])

    const getbyname = async () => {
        const response = await Axios.post('/getblogbyname', { name: path })
        if (response) {
            // console.log(response?.data?.result[0],"response");
            setApdata(response?.data?.result[0])

        }
    }


    useEffect(() => {
        const setHead = () => {
            document.title = apdata?.title;

            // Update meta tags
            const metaDesc = document.querySelector('meta[name="description"]');
            console.log(metaDesc, "metadesc");
            if (metaDesc) {
                metaDesc.setAttribute('content', apdata?.description?.editorData.length > 120 ? `${apdata?.description?.editorData.slice(0, 120)} ...` : apdata?.description?.editorData);
            }

            const ogTitle = document.querySelector('meta[property="og:title"]');
            if (ogTitle) {
                ogTitle.setAttribute('content', apdata?.title);
            }

            const ogDesc = document.querySelector('meta[property="og:description"]');
            if (ogDesc) {
                ogDesc.setAttribute('content', apdata?.description?.editorData.length > 120 ? `${apdata?.description?.editorData.slice(0, 120)} ...` : apdata?.description?.editorData);
            }

            const ogImage = document.querySelector('meta[property="og:image"]');
            if (ogImage) {
                ogImage.setAttribute('content', apdata?.image_url);
            }

            const ogUrl = document.querySelector('meta[property="og:url"]');
            if (ogUrl) {
                ogUrl.setAttribute('content', `${consts.MainUrl}/bloginner/${apdata?.slug_url}` || window.location.href);
            }

            const twitterTitle = document.querySelector('meta[name="twitter:title"]');
            if (twitterTitle) {
                twitterTitle.setAttribute('content', apdata?.title);
            }

            const twitterDesc = document.querySelector('meta[name="twitter:description"]');
            if (twitterDesc) {
                twitterDesc.setAttribute('content', apdata?.description?.editorData.length > 120 ? `${apdata?.description?.editorData.slice(0, 120)} ...` : apdata?.description?.editorData);
            }

            const twitterImage = document.querySelector('meta[name="twitter:image"]');
            if (twitterImage) {
                twitterImage.setAttribute('content', apdata?.image_url);
            }
        };

        setHead();
    }, [apdata]);




    // useEffect(()=>{
    //     document.title = apdata?.title
    //     setMetaTag('name','description',apdata?.description?.editorData.length > 120 ? `${apdata?.description?.editorData.slice(0, 120)} ...` : apdata?.description?.editorData)
    //     setMetaTag('name','keyword',apdata?.title)
    //     setMetaTag('property','og:title',apdata?.title || "BLOG")
    //     setMetaTag('property','og:description',apdata?.description?.editorData.length > 120 ? `${apdata?.description?.editorData.slice(0, 120)} ...` : apdata?.description?.editorData|| "BLOG")
    //     // setMetaTag('property','og:description',apdata?.description?.editorData.length > 120 ? `${apdata?.description?.editorData.slice(0, 120)} ...` : apdata?.description?.editorData|| "BLOG")
    //     setMetaTag('property','og:image',apdata?.image_url)
    //     setMetaTag('property','og:url ',`${consts.MainUrl}/bloginner/${apdata?.slug_url}` || window.location.href)
    //     setMetaTag('property','twitter:title',apdata?.title || "BLOG")
    //     setMetaTag('property','twitter:description',apdata?.description?.editorData.length > 120 ? `${apdata?.description?.editorData.slice(0, 120)} ...` : apdata?.description?.editorData|| "BLOG" || "BLOG")
    //     setMetaTag('property','twitter:image',apdata?.image_url || "BLOG")
    //     setMetaTag('property','twitter:url ',`${consts.MainUrl}/bloginner/${apdata?.slug_url}` || window.location.href)
    //     return()=>{

    //     }

    //  },[apdata ])

    //     const setMetaTag = (attr,key,content) => {
    //         if(content){

    //             let element = document.querySelector(`meta[${attr}="${key}"]`)
    //             if(!element){
    //                 element = document.createElement('meta')
    //                 element.setAttribute(attr,key)
    //                 document.head.appendChild(element)
    //             }
    //             element.setAttribute('content',content)
    //         }
    //     }

    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        const textToCopy = `${consts.MainUrl}bloginner/${apdata?.slug_url}`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        });
    };



    return (
        <div className='blog-inner'>
            {/* <Helmet>
                <title>"creativity , innovation and research are the driving factors of students success."</title>
                <meta name="description" content="In today's modern world, a holistic approach toward educated"/>
                <link rel="icon" href="https://thorecoin.com/api/images/1720431964812_WhatsApp Image 2024-07-08 at 3.13.39 PM.jpeg" />
                <meta property="og:title" content="creativity , innovation and research are the driving factors of students success." />
                <meta name="og:description" content="In today's modern world, a holistic approach toward educated" />
                <meta property="og:image" content="https://thorecoin.com/api/images/1720431964812_WhatsApp Image 2024-07-08 at 3.13.39 PM.jpeg" />
                <meta name="twitter:description" content="In today's modern world, a holistic approach toward educated" />
                <meta name="twitter:title" content="creativity , innovation and research are the driving factors of students success." />
                <meta name="twitter:image" content="https://thorecoin.com/api/images/1720431964812_WhatsApp Image 2024-07-08 at 3.13.39 PM.jpeg" />
            </Helmet> */}

            <ToastContainer />
            <Header setToken={net} solProviders={solProviders} eTHProviders={eTHProviders} setAddress={setAddress} wallets={wallets} />
            <div
                className='banner-Thorecoin'
            >

                <Grid container spacing={0} className='thorecoin-banner' sx={{ justifyContent: 'center' }}>


                    <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                        {/* <div className='blog-inner-main'>
                            <div className='thore-coin-h1'>
                                <h2>{apdata.name}</h2>
                            </div>
                            <div>
                                <p className='para-desc'>{apdata.desc}</p>
                            </div>
                            <div className='para-desc'>
                                <strong>Thorcoin (THOR) </strong> is a cryptocurrency that may relate to Norse mythology, notably Thor, the Norse god of thunder. However, as of my last update, there isn't a widely recognized cryptocurrency named "Thorcoin" with significant market presence, Research the development team behind Thorcoin. Who are they, and what are their backgrounds? What is their vision for Thorcoin's future, and what milestones have they achieved so far? Analyze the roadmap if available to understand the planned evolution of the project.
                            </div>
                        </div> */}
                        <div className='back-arrow-div'>
                            <ArrowBackIcon onClick={() => navigate(-1)} />

                        </div>
                        {
                            apdata && <StyledDiv dangerouslySetInnerHTML={{ __html: apdata?.description?.editorData }} />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={6}>
                        <div className='solutions'><img src={apdata?.image_url} alt="ai-blockchain" /></div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ul class="example-2">
                            <li class="icon-content">
                                <a
                                    href="https://linkedin.com/"
                                    aria-label="LinkedIn"
                                    data-social="linkedin"
                                >
                                    <div class="filled"></div>
                                    <LinkedInIcon />                            </a>
                                <div class="tooltip">LinkedIn</div>
                            </li>
                            <li class="icon-content">
                                <a href='https://www.facebook.com/?ref=logo' aria-label="GitHub" data-social="github">
                                    <div class="filled"></div>
                                    <FacebookIcon />                            </a>
                                <div class="tooltip">Facebook</div>
                            </li>
                            <li class="icon-content">
                                <a
                                    href="https://www.instagram.com/"
                                    aria-label="Instagram"
                                    data-social="instagram"
                                >
                                    <div class="filled"></div>
                                    <InstagramIcon />
                                </a>
                                <div class="tooltip">Instagram</div>
                            </li>
                            <li class="icon-content">
                                <a aria-label="Youtube" href='https://web.whatsapp.com/' data-social="youtube">
                                    <div class="filled"></div>

                                    <WhatsAppIcon />                            </a>
                                <div class="tooltip">Whatsapp</div>
                            </li>
                            <li class="icon-content">
                                <a aria-label="Youtube" data-social="copy">
                                    <div class="filled"></div>

                                    <FileCopyIcon onClick={handleCopy} />                            </a>
                                <div class="tooltip">{copied ? 'Copied!' : 'Copy'}</div>
                            </li>
                        </ul>
                    </Grid>


                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <div className='para-desc'>
                            <ol>
                                <li>
                                    <strong>Origin and Inspiration: </strong>  Discuss how Thorcoin draws inspiration from Norse mythology, particularly the character of Thor. Explore how this theme is reflected in the branding, marketing, and possibly even the technical aspects of the cryptocurrency.
                                </li>
                                <li>
                                    <strong>Symbolism and Imagery: </strong>    Analyze the symbolism and imagery associated with Thor and how it might be used in the context of Thorcoin. For example, Thor's hammer (Mjölnir) could symbolize strength and security in transactions.
                                </li>
                                <li>
                                    <strong>Community and Culture: </strong>  Investigate the community surrounding Thorcoin. How do they perceive and engage with the mythology-based aspects of the cryptocurrency? Are there rituals or community activities that reflect this cultural connection?
                                </li>
                                <li>
                                    <strong> Technical Features:</strong>  While specifics may vary, typical cryptocurrency essays often cover technical aspects like blockchain technology, consensus mechanisms, and transaction protocols. Look into how Thorcoin integrates these technologies and whether there are any unique features or innovations.
                                </li>
                                <li>
                                    <strong>Market Impact and Future:  </strong>  Consider the market presence and future potential of Thorcoin. How does its thematic approach affect its adoption and market performance? What are experts saying about its long-term viability?
                                </li>
                            </ol>
                        </div>
                        <div className='thore-coin-h1>
                            <h4>Crypto Exchange Platform:</h4>
                        </div>
                        <div className='para-desc'>
                            <strong>Thorcoin (THOR) </strong> is a cryptocurrency that may relate to Norse mythology, notably Thor, the Norse god of thunder. However, as of my last update, there isn't a widely recognized cryptocurrency named "Thorcoin" with significant market presence, Research the development team behind Thorcoin. Who are they, and what are their backgrounds? What is their vision for Thorcoin's future, and what milestones have they achieved so far? Analyze the roadmap if available to understand the planned evolution of the project.
                        </div>
                        <div>
                            <p className='para-desc'>{apdata.desc}</p>
                        </div>

                    </Grid> */}
                </Grid>
                <Footer />
            </div>


        </div >
    )
}

export default BlogInner
