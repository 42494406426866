import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion'
import Header from './include/Header'
import Footer from './include/Footer'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CanvasJSReact from '@canvasjs/react-charts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { consts } from '../constant';


const setHead = () => {
  document.title = "ThoreCoin/analytics";

  // Update meta tags
  const metaDesc = document.querySelector('meta[name="description"]');
  if (metaDesc) {
    metaDesc.setAttribute('content','Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
  }

  const ogTitle = document.querySelector('meta[property="og:title"]');
  if (ogTitle) {
    ogTitle.setAttribute('content',"ThoreCoin/analytics");
  }

  const ogDesc = document.querySelector('meta[property="og:description"]');
  if (ogDesc) {
    ogDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
  }

  const ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
  }

  const ogUrl = document.querySelector('meta[property="og:url"]');
  if (ogUrl) {
    ogUrl.setAttribute('content', `${consts.MainUrl}/analytics` || window.location.href);
  }

  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (twitterTitle) {
    twitterTitle.setAttribute('content', "ThoreCoin/analytics");
  }

  const twitterDesc = document.querySelector('meta[name="twitter:description"]');
  if (twitterDesc) {
    twitterDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
  }

  const twitterImage = document.querySelector('meta[name="twitter:image"]');
  if (twitterImage) {
    twitterImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
  }
};

setHead();


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    sidebarcls: {
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        top: '0px',
        padding: '0px !important',
    }
});

const Analytics = () => {
  


    const classes = useStyles();

    const [selected, setSelected] = useState(0)
    // const [selectedTab, setSelectedTab] = useState(0)
    const [formStatus, setFormStatus] = useState(false)

    const handleFormOpen = () => { setFormStatus(true) }
    const handleFormClose = () => { setFormStatus(false) }

    const [dataPoints, setDataPoints] = useState([]);
  const chartRef = useRef(null);

    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }

    useEffect(() => {
        const fetchData = async () => {
          const response = await fetch('https://canvasjs.com/data/gallery/react/nifty-stock-price.json');
          const data = await response.json();
          const points = data.map(item => ({
            x: new Date(item.x),
            y: item.y
          }));
          setDataPoints(points);
          if (chartRef.current) {
            chartRef.current.render();
          }
        };
    
        fetchData();
      }, []);
    
      const options = {
        theme: "light2",
        data: [{
          type: "line",
          xValueFormatString: "MMM YYYY",
          yValueFormatString: "#,##0.00",
          dataPoints: dataPoints
        }],
        axisY: {
            gridColor: "#ccc", // Custom grid color for x-axis
            gridThickness: 1 // Custom grid thickness for x-axis
          }
      };

    const [walletss, wallets] = useState("")

    
  const solProviders = (data) => {
    // setSolProviders(data)
  }

  const eTHProviders = (data) => {
    // setETHProviders(data)
  }
  const [address, setAddress] = useState("")
  const  [net ,setNet] = useState("")
  useEffect(()=>{
   if(net === ""){
    setNet(localStorage.getItem("Network"))
   }else{
    setNet("")
   }
  },[])

    return (
        <div className='landing-page-main presale-mail-page'>
            <ToastContainer />
            <Header  setToken={net} solProviders={solProviders} eTHProviders={eTHProviders} setAddress={setAddress}   wallets={wallets}/>

            <div className='analytics-hero-section'>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Item className={classes.sidebarcls} id='analytics-left'>
                                <div className="analytics-head">
                                    <label>Thore Network PVT LTD</label>
                                </div>
                                <div className="analytics-body">
                                   <table>
                                      <tr>
                                         <td><label>Company Name</label><span>Thore Network Limited</span></td>
                                         <td><label>Scrip Name</label><span>Thore Network</span></td>
                                         <td><label>Last Traded Price </label><span>10000/- +0.000%</span></td>
                                      </tr>
                                      <tr>
                                         <td><label>Sector</label><span>AI Blockchain & Edtech</span></td>
                                         <td><label>PAN No.</label><span>AABCA8810G</span></td>
                                         <td><label>Face Value</label><span>10</span></td>
                                      </tr>
                                      <tr>
                                         <td><label>No. of Outstanding Shares</label><span>459,152</span></td>
                                         <td><label>EPS</label><span>1097.12</span></td>
                                         <td><label>PE ratio</label><span>15.31</span></td>
                                      </tr>
                                      <tr>
                                         <td><label>Market Capitalization</label><span>100 crore</span></td>
                                         <td><label>Book value</label><span>7065.62</span></td>
                                         <td><label>P/BV</label><span>2.38</span></td>
                                      </tr>
                                      <tr>
                                         <td><label>ISIN No.</label><span>INE0WI201019</span></td>
                                         <td><label>Available on</label><span>nsdl EQUITY ZEN</span></td>
                                         <td><label>ROFR Require</label><span>No</span></td>
                                      </tr>
                                      <tr>
                                         <td><label>52 Week High</label><span>10000</span></td>
                                         <td><label>Lifetime High</label><span>10000</span></td>
                                         <td><label>Day High</label><span>10000</span></td>
                                      </tr>
                                      <tr>
                                         <td><label>52 Week Low</label><span>10000</span></td>
                                         <td><label>Lifetime Low</label><span>10000</span></td>
                                         <td><label>Day Low</label><span>10000</span></td>
                                      </tr>
                                   </table>
                                </div>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Item className={classes.sidebarcls} id='analytics-right'>
                                <div className="analytics-head">
                                    <label>Thore Network Price Performance</label>
                                </div>
                                <div className="analytics-body">
                                <CanvasJSChart options={options} onRef={ref => chartRef.current = ref} />
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <Footer />
        </div>
    )
}

export default Analytics
