import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import Header from './include/Header'
import Footer from './include/Footer'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom'
import soln2 from '../images/soln-2.webp'
import soln3 from '../images/soln-3.webp'
import soln4 from '../images/soln-4.webp'
import soln5 from '../images/soln-5.webp'
import soln6 from '../images/soln-6.webp'
import soln7 from '../images/soln-7.webp'
import soln8 from '../images/soln-8.webp'
import landingbannerright from '../images/landingbannerright.webp'
import bw1 from '../images/bw1.webp'
import bw2 from '../images/bw2.webp'
import aicryto1 from '../images/aicryto1.webp'
import aicryto2 from '../images/aicryto2.webp'
import sfca1 from '../images/sfca1.webp'
import sfca2 from '../images/sfca2.webp'
import solutions from '../images/solutions.webp'
import thrfeat1 from '../images/thr-feat-1.webp'
import thrfeat2 from '../images/thr-feat-2.webp'
import thrfeat3 from '../images/thr-feat-3.webp'
import thrfeat4 from '../images/thr-feat-4.webp'
import thorecoinlaunchpad from '../images/thorecoin-launchpad.webp'
import buttonopenclose from '../images/button-open-close.webp'
import accordplus from '../images/accord-plus.webp'
import accordminus from '../images/accord-minus.webp'
import eth from '../images/eth.webp'
import usdt from '../images/usdt.webp'
import card from '../images/card.webp'
import buducoin from '../images/buducoin.webp'
import aicrypto from '../images/aicrypto.webp'
import bnb from '../images/bnb.webp'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import outsmartbgimg from '../images/outsmartbgimg.webp'
import CanvasJSReact from '@canvasjs/react-charts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Avatar } from '@mui/material';
import h1 from '../images/h1.png'
import h2 from '../images/h2.png'
import h3 from '../images/h3.png'
import h4 from '../images/h4.png'
import Tooltip from '@mui/material/Tooltip';
import { consts } from '../constant';


const setHead = () => {
    document.title = "ThoreCoin";

    // Update meta tags
    const metaDesc = document.querySelector('meta[name="description"]');
    if (metaDesc) {
      metaDesc.setAttribute('content','Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
      ogTitle.setAttribute('content',"ThoreCoin");
    }

    const ogDesc = document.querySelector('meta[property="og:description"]');
    if (ogDesc) {
      ogDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const ogImage = document.querySelector('meta[property="og:image"]');
    if (ogImage) {
      ogImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
    }

    const ogUrl = document.querySelector('meta[property="og:url"]');
    if (ogUrl) {
      ogUrl.setAttribute('content', `${consts.MainUrl}/` || window.location.href);
    }

    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    if (twitterTitle) {
      twitterTitle.setAttribute('content', "ThoreCoin");
    }

    const twitterDesc = document.querySelector('meta[name="twitter:description"]');
    if (twitterDesc) {
      twitterDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const twitterImage = document.querySelector('meta[name="twitter:image"]');
    if (twitterImage) {
      twitterImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
    }
  };

  setHead();



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: '#FFF',
    borderRadius: '10px',
    boxShadow: '0px 27px 42px 0px rgba(57, 89, 255, 0.30)',
    p: 4,

    '@media (max-width: 575.98px)': {
        width: '250px !important',
    },
    '@media (min-width: 576px) and (max-width: 767.98px)': {
        width: '350px !important',
    }


};


// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    sidebarcls: {
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        top: '0px',
        padding: '0px !important',
    }
    // headercls: {
    //     background: '#131a26 !important',
    //     borderRadius: '0px !important',
    //     boxShadow:'none !important',
    //     padding:'20px !important',
    //     '& form':{
    //       padding:'0px !important',
    //       '@media (max-width: 767.98px)' : {
    //         width: '100%',
    //       },
    //       '& button': {
    //         background: '#25DEB0',
    //         borderRadius: '0px 5px 5px 0px'
    //       }
    //     },
    //     '@media (max-width: 767.98px)' : {
    //       padding: '20px !important',
    //     },
    // }
});



const Landing = () => {
    document.title = "ThoreCoin";
    const classes = useStyles();

    const [selected, setSelected] = useState(0)
    // const [selectedTab, setSelectedTab] = useState(0)
    const [formStatus, setFormStatus] = useState(false)


    





    const handleFormOpen = () => { setFormStatus(true) }
    const handleFormClose = () => { setFormStatus(false) }

    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }

    const data = [
        { title: 'What is the history of Thorecoin and its development?', answer: 'Thorecoin, developed by Thore Network PVT LTD, represents a significant advancement in the cryptocurrency space, focusing on integrating blockchain technology with financial markets. It was launched as a part of a broader initiative to provide comprehensive digital asset solutions, including a cryptocurrency exchange platform, DeFi integrations, and AI-driven tools for market analysis. Thorecoin aims to facilitate seamless transactions and investments in digital assets, catering to both novice and experienced investors.' },
        { title: 'How can I buy Thorecoin?', answer: 'Thorecoin can be purchased through its dedicated platform or other participating cryptocurrency exchanges. To start, you will need to set up a digital wallet that supports Thorecoin. After registering and verifying your account on the Thorecoin platform or an exchange, you can buy Thorecoin by transferring funds into your account using traditional payment methods like bank transfers or credit cards, or by exchanging other cryptocurrencies for Thorecoin.' },
        { title: 'What investment options does Thorecoin offer that are unique in the market?', answer: 'Thorecoin offers a pioneering investment model by combining equity and token investments. This dual option allows investors to participate in the growth of Thore Network PVT LTD through traditional equity stakes while also engaging in the dynamic world of cryptocurrency through token investments. This hybrid model is designed to cater to a wide range of investors seeking different types of exposure and risk in the digital asset market, making it the first of its kind in the industry.' }
    ]


    const options = {
        animationEnabled: true,
        data: [{
            type: "doughnut",
            showInLegend: false,
            indexLabel: "{name}: {y}",
            yValueFormatString: "#,###'%'",
            startAngle: 300,
            dataPoints: [
                { name: "", y: 35, color: "rgba(68, 82, 154, 1)" },
                { name: "", y: 15, color: "rgba(90, 107, 195, 1)" },
                { name: "", y: 15, color: "rgba(111, 131, 236, 1)" },
                { name: "", y: 15, color: "rgba(145, 162, 250, 1)" },
                { name: "", y: 10, color: "rgba(185, 196, 255, 1)" },
                { name: "", y: 5, color: "rgba(197, 205, 250, 1)" },
                { name: "", y: 5, color: "rgba(226, 231, 255, 1)" }
            ]
        }]
    }



    const targetDate = new Date("2024-12-31T23:59:59");

    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        if (difference <= 0) {
            return {};
        }
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
    }, [targetDate]);
    const [walletss, wallets] = useState("")


    const solProviders = (data) => {
        // setSolProviders(data)
    }

    const eTHProviders = (data) => {
        // setETHProviders(data)
    }
    const [address, setAddress] = useState("")
    const [net, setNet] = useState("")
    useEffect(() => {
        if (net === "") {
            setNet(localStorage.getItem("Network"))
        } else {
            setNet("")
        }
    }, [])


    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://files.coinmarketcap.com/static/widget/currency.js";
        script.async = true;
        script.type = "text/javascript";
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const [openSearch, setOpen] = React.useState(false);
    const handleOpenSearch = () => setOpen(true);
    const handleCloseSearch = () => setOpen(false);

    const [openEducation, setOpenEducation] = React.useState(false);
    const handleOpenEducation = () => setOpenEducation(true);
    const handleCloseEducation = () => setOpenEducation(false);

    const [openThorecoin, setOpenThorecoin] = React.useState(false);
    const handleOpenThorecoin = () => setOpenThorecoin(true);
    const handleCloseThorecoin = () => setOpenThorecoin(false);

    const [openBlockchain, setOpenBlockchain] = React.useState(false);
    const handleOpenBlockchain = () => setOpenBlockchain(true);
    const handleCloseBlockchain = () => setOpenBlockchain(false);

    return (



        <div className='landing-page-main presale-mail-page landing-page-main-page'>
            <ToastContainer />
            <Header setToken={net} solProviders={solProviders} eTHProviders={eTHProviders} setAddress={setAddress} wallets={wallets} />

            <div
                className='banner-Thorecoin'
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} className='thorecoin-banner'>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='thorecoin-banner-left'>
                            <h1>Empowering Crypto with AI-Powered  Business Solutions</h1>
                            <p className='ai-powered-para'>Learn, Invest, and Innovate with Our Comprehensive AI and Cryptocurrency Platform</p>
                            <div className='display-1' style={{ justifyContent: 'flex-start' }}>
                                <Link target='_blank' to='https://tokpie.com/dashboard/make_request/thr-usdt/' className='common-button-link'><span>Buy THR</span></Link>
                                <Tooltip title="Trade with Koinpark(INR)" placement="right">
                                    <Link target='_blank' to='https://www.koinpark.com/trade/THR-INR' className='koin-inr'>      <Avatar alt="Remy Sharp" src={h2} /></Link>
                                </Tooltip>

                            </div>


                            <div className="coinmarketcap-currency-widget"
                                data-currencyid="3144"
                                data-base="USD"
                                data-secondary="BTC"
                                data-ticker="true"
                                data-rank="false"
                                data-marketcap="false"
                                data-volume="true"
                                data-statsticker="true"
                                data-stats="USD">
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='thorecoin-banner-right'>
                            <Item className={classes.sidebarcls} id="presale-form-thorecoin-banner-right-form-id">
                                <div className='landingbannerright'><img src={landingbannerright} alt='landingbannerright'></img></div>
                                {!formStatus ?
                                    <div className='thorecoinlaunchpad' onClick={handleFormOpen}><img src={thorecoinlaunchpad} alt="thorecoinlaunchpad" /></div>
                                    :
                                    <div className='presale-form thorecoin-banner-right-form'>
                                        <legend className="presale-form-head">Next Price Increase In <div className='buttonopenclose' onClick={handleFormClose}><img src={buttonopenclose} alt="buttonopenclose" /></div></legend>
                                        {Object.keys(timeLeft).length === 0 ? (
                                            <span>Time's up!</span>
                                        ) : (
                                            <div className='dhms-outer'>
                                                <div className='dhms'><label>Days</label><span>{timeLeft.days}</span></div>
                                                <div className='dhms'><label>Hours</label><span>{timeLeft.hours}</span></div>
                                                <div className='dhms'><label>Minutes</label><span>{timeLeft.minutes}</span></div>
                                                <div className='dhms'><label>Seconds</label><span>{timeLeft.seconds}</span></div>
                                            </div>
                                        )}
                                        <p className='font-caps'><label>USDT Raised:</label> <span>$5,391,595.54 / $5,607,253</span></p>
                                        <div className='price-increase-time'>
                                            <span className='track-range'></span>
                                            <label>Until price increase</label>
                                            <span className='range-length' style={{ width: '80%' }}></span>
                                        </div>
                                        <p className='font-caps'>Your purchased BUDU= 0</p>
                                        <p className='font-caps'>Your stakeable BUDU = 0</p>
                                        <span className='span-rate'><span className='span-rate-inner font-caps'>1 BUDU = $0.000717</span></span>
                                        <Stack className="presale-coin-selected-btns presale-coin-selected-btns-three-btns">
                                            <div className='presale-plan-in-button'><Button variant="contained" className='active-btn'><img src={eth} alt="eth" />ETH</Button></div>
                                            <div className='presale-plan-in-button'><Button variant="contained"><img src={usdt} alt="usdt" />USDT</Button></div>
                                            <div className='presale-plan-in-button card-option-btn'><Button variant="contained"><img src={card} alt="card" />card</Button></div>
                                        </Stack>
                                        <Stack className="presale-coin-selected-btns text-field-box" direction="row" spacing={2}>
                                            <div className='text-field-box-inner'>
                                                <div className='flex-label'><label>ETH you pay</label><span>Max</span></div>
                                                <div className='text-field-box-inner-inner'>
                                                    <TextField id="outlined-basic" value='0' variant="outlined" />
                                                    <img src={eth} alt="eth" />
                                                </div>
                                            </div>
                                            <div className='text-field-box-inner'>
                                                <div className='flex-label'><label>$Thore you receive</label></div>
                                                <div className='text-field-box-inner-inner'>
                                                    <TextField id="outlined-basic" value='0' variant="outlined" />
                                                    <img src={buducoin} alt="buducoin" />
                                                </div>
                                            </div>
                                        </Stack>
                                        <Stack className="presale-coin-selected-btns equal-width connect-wallet-buy-bnb">
                                            <div className='presale-plan-in-button'><Button variant="contained">Connect Wallet</Button></div>
                                            <div className='presale-plan-in-button'><Button variant="contained"><img src={bnb} alt="bnb" />Buy with BNB</Button></div>
                                        </Stack>
                                    </div>
                                }
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <motion.div className='What-is-Thorecoin'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                viewport={{ once: true }}
            >

                <h2>What is Thore Network?</h2>
                <p className='thore-net-para'>Integrating Thorecoin 2.0, AI Education, and Insights.<br />Explore AI-Powered Crypto Solutions and Advanced Search Engine Capabilities</p>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} className='thore-net-card'>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='inner-thorecoin'>
                            <strong>AI search engine Insights</strong>
                            <p>Thorecoin 2.0 introduces a cutting-edge AI-based search engine tailored for the cryptocurrency market. This innovative tool leverages advanced artificial intelligence algorithms to provide comprehensive, real-time insights into the dynamic world of digital assets. By analyzing ... <Button onClick={handleOpenSearch}>Read more</Button></p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='inner-thorecoin'>
                            <strong>AI education</strong>
                            <p>Thorecoin 2.0 extends its innovative approach to the realm of education with a specialized AI curriculum designed for students in grades 6-10. This program aims to demystify artificial intelligence, providing young learners with a solid foundation in this transformative technology. The curriculum is ... <Button onClick={handleOpenEducation}>Read more</Button></p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='inner-thorecoin'>
                            <strong>Thorecoin 2.0</strong>
                            <p>Thorecoin 2.0 introduces an advanced AI token built on the Solana platform, capitalizing on Solana's high-speed, low-cost blockchain infrastructure. This AI token represents a significant upgrade from Thorecoin 1.0, enhancing functionality, scalability, and security to meet the evolving ... <Button onClick={handleOpenThorecoin}>Read more</Button></p>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='inner-thorecoin'>
                            <strong>Pioneering AI & Blockchain as a Service Solutions</strong>
                            <p>Overview:  Thore Network is at the forefront of technological innovation, dedicated to transforming industries through cutting-edge AI and Blockchain solutions. We offer comprehensive AI & Blockchain as a Service (BaaS) for both government and private sectors, delivering tailored ... <Button onClick={handleOpenBlockchain}>Read more</Button></p>
                        </Grid>
                    </Grid>
                </Box>
            </motion.div>

            <motion.div className='What-is-Thorecoin-outsmart'
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{ duration: 0.2, delay: 0.2 }}
                viewport={{ once: true }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className='outsmart-inner-thorecoin-left'>
                            <label>Are you ready?</label>
                            <h2>OUTSMART THE MARKET WITH THR – Thore Predictive Technology</h2>
                            <Link className='common-button-link' to="/insights"><span>Thorecoin AI Search Engine</span></Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className='outsmart-inner-thorecoin-right'>
                            <div className='outsmartbgimg'><img src={outsmartbgimg} alt="outsmartbgimg" /></div>
                        </Grid>
                    </Grid>
                </Box>
            </motion.div>

            {/* <motion.div className='token-section'
             initial = {{opacity: 0 }}
             whileInView ={{opacity: 1}}
             transition ={{duration: 0.6, delay: 0.5}}
             viewport = {{once: true}}
             id='tokenomics'
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='token-section-heading'>
                            <h3><span>Token</span> <label>Allocation</label></h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
                    </Grid>
                    <Grid container spacing={1} className='tokenomics'>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='token-section-left'>
                            <div className='token-section-left-outer token-section-left-outer-thore'>
                                <div className='token-section-left-inner-left'>
                                    <span>THR</span>
                                </div>
                                <div className='token-section-left-inner'>
                                    <label>Total Number of Tokens</label>
                                    <span>400 000 000 THR</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className='token-section-right'>
                            <div className='CanvasJSChart'>
                                <CanvasJSChart options={options} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className='token-section-right'>
                            <div className='Token-allocation-div'>
                                <label>$THR has total supply of 420,000,000</label>
                                <p>The Thore token serves as the utility token of our
                                    platform, enabling users to access premium content,
                                    participate in community governance, and redeem exclusive
                                    rewards and benefits.</p>

                                <div className='tokenomics-table-part'>

                                    <div className='tokenomics-table-part-head'>
                                        <div>Fund</div>
                                        <div>% Allocation</div>
                                        <div>Token</div>
                                    </div>
                                    <div className='tokenomics-table-part-td'>
                                        <div><span className='round-circle-1'></span>Presale</div>
                                        <div>35%</div>
                                        <div>147,000,000</div>
                                    </div>
                                    <div className='tokenomics-table-part-td'>
                                        <div><span className='round-circle-2'></span>Airdrops for players</div>
                                        <div>15%</div>
                                        <div>63,000,000</div>
                                    </div>
                                    <div className='tokenomics-table-part-td'>
                                        <div><span className='round-circle-3'></span>LP</div>
                                        <div>15%</div>
                                        <div>63,000,000</div>
                                    </div>
                                    <div className='tokenomics-table-part-td'>
                                        <div><span className='round-circle-4'></span>Casino $DICE pool</div>
                                        <div>15%</div>
                                        <div>63,000,000</div>
                                    </div>
                                    <div className='tokenomics-table-part-td'>
                                        <div><span className='round-circle-5'></span>Staking rewards</div>
                                        <div>10%</div>
                                        <div>42,000,000</div>
                                    </div>
                                    <div className='tokenomics-table-part-td'>
                                        <div><span className='round-circle-6'></span>Marketing/KOL deals</div>
                                        <div>5%</div>
                                        <div>21,000,000</div>
                                    </div>
                                    <div className='tokenomics-table-part-td'>
                                        <div><span className='round-circle-7'></span>Affiliates</div>
                                        <div>5%</div>
                                        <div>21,000,000</div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </motion.div> */}

            <motion.div className='token-section ai-crypt0-now-section'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.5 }}
                viewport={{ once: true }}
                id=''
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div className='aicrypto'><img src={aicrypto} alt="aicrypto" /></div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className='token-section-heading ai-crypto-head'>
                            <h3><label>Start Building Your Future With </label><span>AI and Crypto Now</span></h3>
                        </Grid>

                    </Grid>
                    <Grid container spacing={1} className='cards-ai-crypto'>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className='token-section-left'>
                            <h4>AI Education for All</h4>
                            <p>Our courses are structured to gradually build your knowledge, starting with the basics of blockchain and cryptocurrencies, and advancing to more complex topics such as smart contracts and artificial intelligence in finance.
                                Artificial Intelligence helps determine what a student does and does not know, helping us build a personalized study schedule for each learner considering their knowledge gaps. Thought of it this way, AI tailors studies according to students’ specific needs, increasing the efficiency of the entire teaching process.
                                Companies can train their AIs to cement knowledge gaps, explain complex concepts easily, define how they relate to each other, do more things like this, and finally bridge the digital divide.</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className='token-section-right'>
                            <div className='aicryto-img'><img src={aicryto1} alt="aicryto1" /></div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className='cards-ai-crypto'>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className='token-section-right'>
                            <div className='aicryto-img'><img src={aicryto2} alt="aicryto2" /></div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className='token-section-left'>

                            <h4>Crypto education</h4>
                            <p>Our courses are structured to gradually build your knowledge, starting with the basics of blockchain and cryptocurrencies, and advancing to more complex topics such as smart contracts and artificial intelligence in finance.</p>
                            <div className='scroll-div'>
                                <h5>Basic Course Structure:</h5>
                                <h6>Introduction to Cryptocurrencies and Blockchain</h6>
                                <div className='card_inner'>
                                    <h4>What are cryptocurrencies?</h4>
                                    <p>Understanding blockchain technology
                                        The history of Bitcoin and other cryptocurrencies
                                        How Cryptocurrencies Work</p>
                                </div>

                                <div className='card_inner'>
                                    <h4>How transactions are made</h4>
                                    <p>The concept of mining
                                        Security and cryptography
                                        Thorecoin and Its Features</p>
                                </div>

                                <div className='card_inner'>
                                    <h4>Introduction to Thorecoin</h4>
                                    <p>Key features of Thorecoin
                                        How Thorecoin is different from other cryptocurrencies
                                        Smart Contracts and Decentralization</p>
                                </div>

                                <div className='card_inner'>
                                    <h4>What are smart contracts?</h4>
                                    <p>Examples of smart contracts
                                        The importance of decentralization
                                        Artificial Intelligence in Finance</p>
                                </div>

                                <div className='card_inner'>
                                    <h4>Basics of artificial intelligence</h4>
                                    <p>How AI is used in finance
                                        The role of AI in Thorecoin
                                        Practical Applications and Future Trends</p>
                                </div>
                                <div className='card_inner'>
                                    <h4>How to stay informed and safe in the crypto world</h4>
                                    <p>Real-world applications of blockchain and cryptocurrencies</p>
                                    <p>Future trends in cryptocurrency and blockchain technology</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </motion.div>

            <div className='token-section'
            // initial={{ scale: 0 }}
            // whileInView={{ scale: 1 }}
            // transition={{ duration: 0.2, delay: 0.2 }}
            // viewport={{ once: true }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} className='tokenomics'>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='token-section-left'>
                            <div className='solutions'><img src={solutions} alt="solutions" /></div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9} className='token-section-right'>
                            <Grid container spacing={1} className='tokenomics-inner'>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <h4 className='solutions-head'>Invest in Advanced AI and Blockchain Solutions Today.</h4>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <div className='solutions-inner'>
                                        <div><img src={soln2} alt="soln2" /><span>AI-Based Search Engine </span></div>
                                        <p>A specialized search engine tailored to provide real-time data, analytics, and insights into various cryptocurrencies. This tool leverages artificial intelligence to offer accurate and timely information, making it easier for investors and traders to make informed decisions.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <div className='solutions-inner'>
                                        <div><img src={soln2} alt="soln2" /><span>DeFi Integration</span></div>
                                        <p>Thorecoin's native token, is integrated with Decentralized Finance (DeFi) protocols. This allows users to engage in staking, lending, borrowing, and earning interest on their holdings. The token's DeFi integration aims to provide liquidity and enhance the overall functionality of the Thorecoin ecosystem.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <div className='solutions-inner'>
                                        <div><img src={soln3} alt="soln3" /><span>Equity and Token Investment</span></div>
                                        <p>A specialized search engine tailored to provide real-time data, analytics, and insights into various cryptocurrencies. This tool leverages artificial intelligence to offer accurate and timely information, making it easier for investors and traders to make informed decisions.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <div className='solutions-inner'>
                                        <div><img src={soln4} alt="soln4" /><span>Fundraising Initiatives</span></div>
                                        <p>Structured fundraising campaigns to attract investment into Thorecoin's various projects. These initiatives might include Initial Coin Offerings (ICOs), Security Token Offerings (STOs), or other modern fundraising methods that leverage blockchain technology.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <div className='solutions-inner'>
                                        <div><img src={soln5} alt="soln5" /><span>Digital Asset Platform </span></div>
                                        <p>A comprehensive trading platform that supports a wide range of digital assets. This platform would provide users with advanced trading tools, secure transactions, and access to various cryptocurrencies. Features might include spot trading, futures, and margin trading.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <div className='solutions-inner'>
                                        <div><img src={soln6} alt="soln6" /><span>Educational Projects on AI</span></div>
                                        <p>Initiatives aimed at educating the younger generation about artificial intelligence. This includes courses designed for students in grades 6-10, focusing on basic AI concepts and practical projects to foster a deeper understanding of AI technologies.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <div className='solutions-inner'>
                                        <div><img src={soln7} alt="soln7" /><span>Innovative Financial Products</span></div>
                                        <p>Developing new financial products that leverage blockchain and AI technologies. These products could include smart contracts, automated trading bots, and AI-driven financial analysis tools.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <div className='solutions-inner'>
                                        <div><img src={soln8} alt="soln8" /><span>Blockchain and AI Integration</span></div>
                                        <p>Continuously improving the integration of blockchain technology with AI to create more efficient, transparent, and secure systems. This integration aims to enhance the functionality and reliability of Thorecoin's various platforms and services.</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <motion.div className='What-is-Thorecoin-outsmart howthrn'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                viewport={{ once: true }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='howthrn-heading'>
                            <h3>How To Buy THR?</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='howthrn'>
                            <div className='howthrn-inner-block'>
                                <Avatar alt="Remy Sharp" src={h1} />
                                <label>Trade with Tokpie</label>
                                <p>Buy, Sell and trade THR exclusively and instantly now at Tokpie. Buy now easily with the link below:<Link style={{ color: "#fff" }} to='https://tokpie.com/dashboard/make_request/thr-usdt/'>https://tokpie.com/dashboard/make_request/thr-usdt/</Link> </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='howthrn'>
                            <div className='howthrn-inner-block'>
                                <Avatar alt="Remy Sharp" src={h2} />
                                <label>Trade with Koinpark</label>
                                <p>Buy, Sell and trade THR exclusively and instantly now at Koinpark.
                                    Buy now easily with the link below :<Link style={{ color: "#fff" }} target='_blank' to='https://www.koinpark.com/trade/THR-USDT'> https://www.koinpark.com/trade/THR-USDT</Link>
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='howthrn'>
                            <div className='howthrn-inner-block'>
                                <Avatar alt="Remy Sharp" src={h3} />
                                <label>Stake and Earn</label>
                                <p>By holding Thorecoin, you can enjoy an impressive 36% APR through staking. This is a fantastic opportunity to grow your assets passively while being part of a revolutionary ecosystem.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='howthrn'>
                            <div className='howthrn-inner-block'>
                                <Avatar alt="Remy Sharp" src={h4} />
                                <label>Exclusive Bonuses</label>
                                <p>Holding Thorecoin also gives you a 25-50% bonus in the highly anticipated Premier Memecoin, BuBuDuDu! This exciting offer ensures you get the most out of your investment in both Thorecoin and BuBuDuDu.</p>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </motion.div>

            <motion.div className='smart-finance-crypto sfca-section'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                viewport={{ once: true }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='howthrn-heading'>
                            <h3>"Smart Finance, Crypto, and Artificial Intelligence"
                                is a Must-Have Program for You</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className=''>
                            <div className='sfca-inner'>
                                <div className='sfca'><img src={sfca1} alt='sfca' /></div>
                                <p>The world is changing rapidly, and Al is becoming a "Must-Haven tool for success.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className=''>
                            <div className='sfca-inner'>
                                <div className='sfca'><img src={sfca2} alt='sfca' /></div>
                                <p>Cryptocurrency makes the world a better place with its transparency and less time consuming attributes.</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <div className='buy-with-head'><span>Buy With</span></div>
                        <div className='buy-with-two'>
                            <div className='buy-with-img'>
                                <Link target='_blank' to="https://www.amazon.in/Thorecoin-Innovators-course-students-grades/dp/B0D2Y1MD77"><img src={bw1} alt='bw1' /></Link>

                            </div>
                            <div className='buy-with-img'>
                                <Link target='_blank' to="https://www.flipkart.com/thorecoin-intro-ai-young-innovators-basic-course-students-grades-6-10/p/itmce11d7315caca?pid=EDMHY78PVDNX5FTB&lid=LSTEDMHY78PVDNX5FTBDR63LG&marketplace=FLIPKART&cmpid=content_educational-media_8965229628_gmc">
                                    <img src={bw2} alt='bw2' />
                                </Link>
                            </div>
                        </div>
                    </Grid>
                </Box>
            </motion.div>



            {/* <motion.div className='token-section roadmap-section'
            initial = {{x: -1000}}
            whileInView ={{x: 0}}
            transition ={{duration: 0.6, delay: 0.8}}
            viewport = {{once: true}}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className='token-section-heading'>
                            <h3><span>ROAD</span> <label>MAP</label></h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className='roadmap-top-para-Total-Budget-Allocation'>
                                <p className='roadmap-top-para'>Our initiative is not merely about introducing another financial service but about reshaping the fabric of global currency dynamics. For the first time in history, Gaugecash is strategically positioned to challenge the hegemony of established reserve currencies like the USD and EUR. This robust and secure monetary framework offers users and investors from every corner of the world the unprecedented opportunity to be part of what might become the most substantial venture in modern financial history.</p>
                                <p className='Total-Budget-Allocation'>Total Budget Allocation: Approximately $23,000,000 - $32,500,000</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className='tokenomics'>
                        <div className='roadmapline'><img src={roadmapline} alt="roadmapline" /></div>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='roadmap-inner-phase phase-1'>
                            <div className='token-section-left-outer'>
                                <div className='token-section-left-inner-left'>
                                    <span>Phase 1</span>
                                </div>
                                <div className='token-section-left-inner'>
                                    <ul>
                                        <li>Contract Audit</li>
                                        <li>Presale Begins</li>
                                        <li>Activate Socials</li>
                                        <li>First Marketing Push</li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='roadmap-inner-phase phase-2'>
                            <div className='token-section-left-outer'>
                                <div className='token-section-left-inner-left'>
                                    <span>Phase 2</span>
                                </div>
                                <div className='token-section-left-inner'>
                                    <ul>
                                        <li>Development Begins</li>
                                        <li>Presale Concludes</li>
                                        <li>Token Launch on DEX</li>
                                        <li>Product Roadmap</li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='roadmap-inner-phase phase-3'>
                            <div className='token-section-left-outer'>
                                <div className='token-section-left-inner-left'>
                                    <span>Phase 3</span>
                                </div>
                                <div className='token-section-left-inner'>
                                    <ul>
                                        <li>Marketing Pump</li>
                                        <li>App Testing</li>
                                        <li>Mini-Game Beta</li>
                                        <li>First CEX Listings</li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} className='roadmap-inner-phase phase-4'>
                            <div className='token-section-left-outer'>
                                <div className='token-section-left-inner-left'>
                                    <span>Phase 4</span>
                                </div>
                                <div className='token-section-left-inner'>
                                    <ul>
                                        <li> App Release</li>
                                        <li>Launch of Mini-Games</li>
                                        <li>Community Airdrop</li>
                                        <li>Further CEX Listings</li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </motion.div> */}

            <motion.div className='seven-companies'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                viewport={{ once: true }}
            >
                <p>Featured In</p>
                <div className='seven-companies-inner'>
                    <div><Link to='https://www.cnbctv18.com/business/thorecoin-ai-token-aims-to-merge-cutting-edge-ai-with-blockchain-innovation-17638551.htm '><img src={thrfeat1} alt="thrfeat1" /></Link></div>
                    <div><Link to='https://www.business-standard.com/content/press-releases-ani/thore-network-set-to-introduce-thorecoin-2-0-with-ai-capabilities-123062300579_1.html'><img src={thrfeat2} alt="thrfeat2" /></Link></div>
                    <div><Link to='https://www.cointrust.com/market-news/thorecoin-ai-token-fusing-ai-and-blockchain-for-digital-economy-evolution'><img src={thrfeat3} alt="thrfeat3" /></Link></div>
                    <div><Link to='https://www.timesnownews.com/india/influential-business-leaders-of-the-year-2023-article-101847774'><img src={thrfeat4} alt="thrfeat4" /></Link></div>
                </div>
            </motion.div>

            <div className='faq-section'>
                <h4>Frequently Asked Questions</h4>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        {data?.map((data, i) =>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={i} className="accord-title-cont">
                                <Item className={classes.sidebarcls}>
                                    <div className="accord-title" onClick={() => toggle(i)}>{data.title}
                                        {/* <i className={selected == i ? "fa-solid fa-minus" : "fa-solid fa-plus"}></i> */}
                                        <div className='accordminus-accordplus'><img src={selected === i ? accordminus : accordplus} alt='img' /></div>
                                    </div>
                                    <div className={selected === i ? "accord-content show" : "accord-content"}>{data.answer}</div>
                                </Item>
                            </Grid>
                        )
                        }
                    </Grid>
                </Box>
            </div>

            <Footer />

            {/* AI search popup */}
            {
                <Modal
                    open={openSearch}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Box sx={style} className='read_more_popup'>
                        <div className='close-btn' onClick={handleCloseSearch}><HighlightOffIcon /></div>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            AI search engine Insights
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} className='scroll-div'>
                            <div className='flex-div'>
                                <PlayArrowIcon />Thorecoin 2.0 introduces a cutting-edge AI-based search engine tailored for the cryptocurrency market. This innovative tool leverages advanced artificial intelligence algorithms to provide comprehensive, real-time insights into the dynamic world of digital assets. By analyzing vast amounts of data from various sources, the AI search engine delivers accurate and timely information on cryptocurrency prices, market trends, trading volumes, and sentiment analysis.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />Key features include personalized search results that cater to individual user preferences, making it easier for traders and investors to find relevant data quickly. The engine also integrates advanced predictive analytics, helping users forecast market movements and identify potential investment opportunities. Additionally, it offers robust security measures to ensure data integrity and user privacy.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />The AI search engine's intuitive interface is designed for both novice and experienced users, providing detailed visualizations and customizable dashboards to track portfolio performance. It also supports natural language processing, allowing users to query the engine using everyday language, making it accessible to a broader audience.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />Thorecoin 2.0's AI search engine is set to revolutionize how users interact with the cryptocurrency market, providing a powerful tool for informed decision-making and strategic investment. This innovation underscores Thorecoin's commitment to enhancing user experience and fostering greater transparency in the digital asset ecosystem.
                            </div>
                        </Typography>
                    </Box>
                </Modal>
            }

            {/* AI Education popup */}
            {
                <Modal
                    open={openEducation}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Box sx={style} className='read_more_popup'>
                        <div className='close-btn' onClick={handleCloseEducation}><HighlightOffIcon /></div>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            AI education
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} className='scroll-div'>
                            <div className='flex-div'>
                                <PlayArrowIcon />Thorecoin 2.0 extends its innovative approach to the realm of education with a specialized AI curriculum designed for students in grades 6-10. This program aims to demystify artificial intelligence, providing young learners with a solid foundation in this transformative technology. The curriculum is structured to be engaging and accessible, combining theoretical knowledge with practical, hands-on projects.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />Key components of the AI education initiative include interactive lessons on the basics of AI, machine learning, and data science. Students will explore real-world applications of AI, such as image recognition, natural language processing, and robotics, fostering a deep understanding of how AI impacts various industries. The program also emphasizes ethical considerations, ensuring students appreciate the responsible use of AI technology.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />To enhance learning, Thorecoin 2.0 incorporates live projects where students can apply their knowledge to solve real-world problems. These projects encourage creativity, critical thinking, and collaboration, preparing students for future careers in AI and technology fields. Additionally, the curriculum includes resources like tutorials, webinars, and mentorship from AI experts, providing comprehensive support to students.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />Thorecoin 2.0's AI education program aims to inspire the next generation of innovators, equipping them with the skills and knowledge needed to thrive in a tech-driven world. By making AI education accessible and engaging, Thorecoin is fostering a community of young learners ready to shape the future.
                            </div>
                        </Typography>
                    </Box>
                </Modal>
            }

            {/* Thorecoin popup */}
            {
                <Modal
                    open={openThorecoin}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Box sx={style} className='read_more_popup'>
                        <div className='close-btn' onClick={handleCloseThorecoin}><HighlightOffIcon /></div>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Thorecoin 2.0
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} className='scroll-div'>
                            <div className='flex-div'>
                                <PlayArrowIcon />Thorecoin 2.0 introduces an advanced AI token built on the Solana platform, capitalizing on Solana's high-speed, low-cost blockchain infrastructure. This AI token represents a significant upgrade from Thorecoin 1.0, enhancing functionality, scalability, and security to meet the evolving needs of the digital asset market.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />Key features of the Thorecoin 2.0 AI token include its integration with decentralized finance (DeFi) protocols, enabling users to engage in activities such as staking, lending, and earning interest. The Solana-based token leverages smart contracts to automate these processes, ensuring transparency and efficiency. Additionally, the token facilitates seamless, low-cost transactions, making it ideal for everyday use and microtransactions.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />The AI token also plays a pivotal role in Thorecoin's ecosystem, powering the innovative AI-based search engine and supporting educational initiatives. Users can utilize the token to access premium features, participate in governance decisions, and contribute to community-driven projects. This integration ensures that the token is not only a medium of exchange but also a tool for fostering active community involvement.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />Thorecoin 2.0, as an upgraded version of Thorecoin 1.0, brings enhanced performance, greater utility, and broader adoption potential. By leveraging Solana's robust platform, Thorecoin 2.0 aims to revolutionize the digital asset landscape, providing users with a versatile and efficient cryptocurrency for the future.
                            </div>
                        </Typography>
                    </Box>
                </Modal>
            }

            {/* Blockchain popup */}

            {
                <Modal
                    open={openBlockchain}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Box sx={style} className='read_more_popup'>
                        <div className='close-btn' onClick={handleCloseBlockchain}><HighlightOffIcon /></div>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Pioneering AI & Blockchain as a Service Solutions
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} className='scroll-div'>
                            <p className='servo'>Overview:</p>
                            <div className='flex-div'>
                                <PlayArrowIcon />Thore Network is at the forefront of technological innovation, dedicated to transforming industries through cutting-edge AI and Blockchain solutions. We offer comprehensive AI & Blockchain as a Service (BaaS) for both government and private sectors, delivering tailored solutions that meet the unique needs of each client.
                            </div>
                            <p className='servo'>Our Services:</p>
                            <h6>Government AI & Blockchain Projects:</h6>
                            <div className='flex-div'>
                                <PlayArrowIcon />Smart Cities: Implementing blockchain to enhance transparency and efficiency in urban management. Integrating AI to optimize resource allocation, traffic management, and public services.
                                E-Governance: Developing secure and transparent platforms for digital voting, identity verification, and record keeping.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />Enhancing citizen engagement through AI-driven data analysis and service personalization.
                                Public Health: Utilizing AI to predict and manage public health trends. Leveraging blockchain for secure health records and efficient supply chain management of medical resources.
                                Private Sector AI & Blockchain Projects:
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />Finance & Banking: Deploying blockchain for secure and transparent transactions, smart contracts, and fraud prevention. Implementing AI to enhance customer experience through personalized financial services and predictive analytics.
                                Supply Chain Management: Creating blockchain solutions for end-to-end visibility and traceability. Utilizing AI to optimize logistics, inventory management, and demand forecasting.
                            </div>
                            <div className='flex-div'>
                                <PlayArrowIcon />Retail & E-commerce: Enhancing customer experience with AI-driven recommendations and personalized marketing. Using blockchain for secure payment solutions and supply chain transparency.
                            </div>
                        </Typography>
                    </Box>
                </Modal>
            }



        </div >
    )
}

export default Landing
