import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import logobudu from '../../images/logo-budu.webp'
import widgetlogo from '../../images/widget-logo.png'
import widget from '../../images/widget.gif'
import widgetGrop from '../../images/widget-Grop.webp'
import socialicon1 from '../../images/socialicon1.webp'
import socialicon2 from '../../images/socialicon2.webp'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import { Box } from '@mui/material/Box';
import { Link, json } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Height } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import SendIcon from '@mui/icons-material/Send';
import OpenAI from "openai";
import axios from 'axios';
import { useState } from 'react';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const style = {
  position: 'absolute',
  right: '45px',
  bottom: '100px',
  // transform: 'translate(-50%, -50%)',
  width: 430,
  height: 700,
  bgcolor: 'background.paper',
  // boxShadow: 24,
  p: 0,
  borderRadius: '10px',
  outline: 'none',

  '@media (max-width: 575.98px)': {
    width: 350,
  },

  '@media (max-width: 1999.98px)': {
    height: 550,
    width: 350,
  },

};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  sidebarcls: {
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    top: '0px',
    padding: '0px !important',
  }
  // headercls: {
  //     background: '#131a26 !important',
  //     borderRadius: '0px !important',
  //     boxShadow:'none !important',
  //     padding:'20px !important',
  //     '& form':{
  //       padding:'0px !important',
  //       '@media (max-width: 767.98px)' : {
  //         width: '100%',
  //       },
  //       '& button': {
  //         background: '#25DEB0',
  //         borderRadius: '0px 5px 5px 0px'
  //       }
  //     },
  //     '@media (max-width: 767.98px)' : {
  //       padding: '20px !important',
  //     },
  // }
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    p: 4,
    borderRadius: '4px',
  },
}));

const Footer = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [input, setInput] = useState('');

  const [response, setResponse] = useState([]);
  const [inputda, setInputda] = useState([])
  //   const openai = new OpenAI({
  //     organization: "org-g0g0ozr3yvNOc2CiWVZp7ycP",
  //     project: "$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",
  // });

  const onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      sendMessage();
    }
  }

  const sendMessage = async (message = input) => {
    try {
      const apiUrl = 'https://api.openai.com/v1/chat/completions'; // Update with the correct API endpoint
      const apiKey = 'sk-proj-5PQbhTY5rhpQcgNZ1Aa6T3BlbkFJbhyW29XpjjnWFoKbcEtr'; // Replace with your actual API key
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      };

      const requestBody = {
        model: "gpt-3.5-turbo",
        messages: [{ role: 'system', content: message }],
        max_tokens: 150,

      };
      const { data } = await axios.post(apiUrl, requestBody, { headers });

      setInput('')

      let da
      da = data.choices[0].message.content;
      setResponse(oldArray => ([...oldArray, message]))
      setResponse(oldArray => ([...oldArray, da]));
    } catch (error) {
      console.error('Error sending message:', error);
    }

  };


  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: 12,
      border: '1px solid #dadde9',
      borderRadius: '4px',
    },
  }));


  const [prebuild, setPrebuild] = useState([
    { name: 'What is Thore?' },
    { name: 'Tell me about Thorecoin' },
    { name: 'What is Thore Network' }
  ])

  const handlePrebuild = (value) => {
    setInput(value);
    console.log(value, 'valval');
    // sendMessage(value)
  }
  return (
    <div className='footer-section'>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <div className='logobudu'><img src={logobudu} alt="logobudu" /></div>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Item className={classes.sidebarcls}>
              <div className='footer-1'>
                <h5>Subscribe to our newsletter</h5>
                <div className='subscribe-footer'>
                  <TextField id="outlined-basic" placeholder="Email" variant="outlined" />
                  <Button variant="contained">Send</Button>
                </div>
              </div>
            </Item>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={1} xl={1}></Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Item className={classes.sidebarcls}>
              <ul className='fot-ul'>
                <li><Link>Features</Link></li>
                <li><Link>Security</Link></li>
                <li><Link>Get started</Link></li>
                <li><Link>FAQ</Link></li>
                <li><Link className='display-4'><MarkunreadIcon />Email</Link></li>
                <li><Link className='display-4' to="mailto:info@thorenetwork.com" ><ArrowForwardIcon />info@thorenetwork.com</Link></li>
                <li><Link className='display-4' to="mailto:nikstrade@thorecoin.com"><ArrowForwardIcon />nikstrade@thorecoin.com</Link></li>
              </ul>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Item className={classes.sidebarcls}>
              <ul>
                <li><Link>Cookie policy</Link></li>
                <li><Link>Terms of use</Link></li>
                <li><Link>Privacy policy</Link></li>
              </ul>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
            <Item className={classes.sidebarcls}>
              <ul className='social-icons-list'>
                <li><Link to="https://discord.com/invite/KKUgZbv" target='_blank'><img src={socialicon1} alt="socialicon2" /></Link></li>

                <li><Link to="https://x.com/ThorecoinAI" target='_blank'><img src={socialicon2} alt="socialicon2" /></Link></li>
              </ul>
            </Item>
          </Grid>
        </Grid>
      </Box>


      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <div className='widget' onClick={handleOpen}><img src={widget} alt="logobudu" /></div>
        </Grid>
      </Box>


      {
        <Modal
          keepMounted
          open={open}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style} className='widget_chart'>
              <div className='bot_head'>
                <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                  <img src={widgetlogo} alt="logobudu" />Thorecoin Insights
                </Typography>
                <div className='min-icon' onClick={handleClose}>
                  <LightTooltip title="Minimize" placement="left">
                    <KeyboardArrowDownIcon />
                  </LightTooltip>
                </div>
              </div>

              <div className='offline-message'>
                <span>We are online</span>
              </div>


              <div className='message-body_default'>
                {prebuild.map((row, ind) => {
                  return (
                    <Tooltip title={<Typography>{row.name}</Typography>} arrow>
                      <div className='widget_content default' >
                        <p class="message-content" onClick={() => handlePrebuild(row.name)}>{row.name}</p>

                      </div>
                    </Tooltip>
                  )
                })}

              </div>


              {/* 
            <div className='message-body_default'>
              <Tooltip title={<Typography>How can I buy Thorecoin?</Typography>} arrow>
                <div className='widget_content default'>
                  <p class="message-content">How can I buy Thorecoin?</p>
                </div>
              </Tooltip>
              <Tooltip title={<Typography>What is Thorecoin AI Search Engine?</Typography>} arrow>
                <div className='widget_content default'>
                  <p class="message-content">What is Thorecoin AI Search Engine?</p>
                </div>
              </Tooltip>

            </div> */}

              <div className='message_flex-grid'>

                <div className='message-body'>

                  {/* { inputda?.map(item =>(<div className='widget_content right'>
               <span class="message-content">{item}</span>
              </div>))} */}

                  {response?.map((item, index) => {
                    return (
                      <>{index % 2 != 0 ?
                        <div className='widget_content left'>
                          <span class="message-content">{item}</span>
                        </div>
                        :
                        <div className='widget_content right'>
                          <span class="message-content">{item}</span>
                        </div>
                      }
                      </>
                    )

                  })}
                  {/* 
              <div className='widget_content right'>
                <span class="message-content">Could you provide some details about the problem you encountered?</span>
              </div>

              <div className='widget_content left'>
                <span class="message-content">Could you provide some details about the problem?</span>
              </div>

              <div className='widget_content right'>
                <span class="message-content">Could you provide some details about the problem you encountered?</span>
              </div>

              <div className='widget_content left'>
                <span class="message-content">Could you provide some details about the problem?</span>
              </div> */}
                </div>
              </div>

              <div className='chat-foot'>
                <TextField id="outlined-basic" placeholder="Enter your message..." variant="outlined" value={input}
                  onChange={(e) => setInput(e.target.value)} onKeyDown={onKeyDown} />
                <Button className='send_btn' onClick={() => sendMessage()}><SendIcon /></Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      }
    </div >
  )
}

export default Footer
