import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom'
import logobudu from '../../images/logo-budu.webp'
// import presalemenu from '../../images/presale-menu.webp'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { FaBars } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { consts } from '../../constant';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  sidebarcls: {
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    top: '0px',
    padding: '0px !important',
  }
  // headercls: {
  //     background: '#131a26 !important',
  //     borderRadius: '0px !important',
  //     boxShadow:'none !important',
  //     padding:'20px !important',
  //     '& form':{
  //       padding:'0px !important',
  //       '@media (max-width: 767.98px)' : {
  //         width: '100%',
  //       },
  //       '& button': {
  //         background: '#25DEB0',
  //         borderRadius: '0px 5px 5px 0px'
  //       }
  //     },
  //     '@media (max-width: 767.98px)' : {
  //       padding: '20px !important',
  //     },
  // }
});

const Header = ({ setToken, solProviders, eTHProviders, setAddress, wallets }) => {
  const classes = useStyles();
  const [mobileMenu, setMobileMenu] = useState(true)

  const [walletAddress, setWalletAddress] = useState(null);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const mobile = useMediaQuery(theme.breakpoints.up('sm'));

  const getProvider = () => {
    const provider = window.phantom?.solana;

    if (provider?.isPhantom) {
      return provider;
    } else {
      window.open('https://phantom.app/', '_blank');
      return null; // Return null if Phantom is not detected or not accessible
    }


  };

  const ethereumProvider = () => {
    const provider = window.phantom?.ethereum;

    if (provider) {
      return provider;
    }
    else {
      window.open('https://phantom.app/', '_blank');
    }


  }

  const handleConnect = async () => {
    const phantomProvider = getProvider();
    if (phantomProvider) {
      try {

        // Request connection to the wallet
        const wallet = await phantomProvider.connect();
        wallets(wallet)
        solProviders(phantomProvider)

        // Get wallet address
        const publicKey = phantomProvider.publicKey;
        setAddress(publicKey)
        setWalletAddress(publicKey?.toString()); // Store wallet address in state
        if (!localStorage.getItem("Network")) {
          const phantomProvider = ethereumProvider();
          eTHProviders(phantomProvider)
          const accounts = await phantomProvider.request({ method: "eth_requestAccounts" });
          localStorage.setItem("ETHADD", accounts[0])
          toast.success("Wallet Connected Successfully")
        }


        localStorage.setItem("Network", "SOL")
        localStorage.setItem("SOLADD", publicKey?.toString())
      } catch (error) {
        console.error('Failed to connect to wallet:', error);
      }
    }
  };


  const ethereumHandleConnect = async () => {
    const phantomProvider = ethereumProvider();
    eTHProviders(phantomProvider)
    if (phantomProvider) {
      try {
        // Request connection to the wallet
        const accounts = await phantomProvider.request({ method: "eth_requestAccounts" });
        setAddress(accounts[0])
        setWalletAddress(accounts[0]); // Store wallet address in state
        localStorage.setItem("Network", "ETH")
      } catch (error) {
        console.error('Failed to connect to wallet:', error);
      }
    }
  };

  const handleDisconnect = () => {
    // Logic to disconnect the wallet
    setAddress("")
    localStorage.setItem("Network", "")
    localStorage.setItem("SOLADD", "")
    localStorage.setItem("ETHADD", "")
    setWalletAddress(null); // Clear the wallet address state
    toast.success("Wallet Disconnected Successfully")
  };

  const handleMenuOpen = () => {
    setMobileMenu(false)
  }

  const handleMenuClose = () => {
    setMobileMenu(true)
  }

  useEffect(() => {
    if (setToken === "ETH") {
      ethereumHandleConnect()
    } else if (setToken === "SOL") {
      handleConnect()
    }
  }, [setToken])
  
  const navi =  () => {
    window.location.replace("https://blog.thorecoin.com/")
  }

  return (
    <div className='header-section'>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
            <Item className={classes.sidebarcls} id="header-left">
              <div className='logobudu'><Link to="/"><img src={logobudu} alt="logobudu" style={{ width: '150px' }} /></Link><FaBars onClick={handleMenuOpen} /></div>
              <ul className='header-menu' id={!mobileMenu ? "open-menu-id" : ""}>
                <li className='mobile-menu-close-icon'><IoMdCloseCircleOutline onClick={handleMenuClose} /></li>
                <li className='presalemenu'><Link to="/presale">BuDu</Link></li>
                <li><Link to="/equity">Equity</Link></li>
                <li><Link to="/exchange">Exchange</Link></li>
                <li><Link to="/insights">Insights</Link></li>
                <li><Link to={`${consts.BlogUrl}`} >Blog</Link></li>
                <Stack
                  direction="row"
                  className='mobile-btns'
                  justifyContent={matches ? "flex-end" : "center"}
                  alignItems="center"
                  flexDirection={!mobile ? 'column' : 'row'}
                  gap={!mobile ? '12px' : null}
                  spacing={2}
                >
                  <div className='presale-plan-in-button'>
                    {walletAddress ? (
                      <Button variant="contained">
                        {walletAddress.slice(0, 8)}...
                      </Button>
                    ) : (
                      <Button variant="contained" onClick={() => { handleConnect() }}>
                        Wallet
                      </Button>
                    )}
                  </div>
                  {walletAddress && (
                    <div className='presale-plan-in-button'>
                      <Button variant="contained" onClick={() => { handleDisconnect() }}>
                        Disconnect
                      </Button>
                    </div>
                  )}
                  <div className='presale-plan-in-button'>
                    <Button variant="contained">Buy now</Button>
                  </div>
                </Stack>
              </ul>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5} >
            <Item className={classes.sidebarcls} id="header-right">
              {matches && <Stack
                direction="row"
                justifyContent={matches ? "flex-end" : "center"}
                alignItems="center"
                spacing={2}
              >
                <div className='presale-plan-in-button'>
                  {walletAddress ? (
                    <Button variant="contained">
                      {walletAddress.slice(0, 8)}...
                    </Button>
                  ) : (
                    <Button variant="contained" onClick={() => { handleConnect() }}>
                      Connect Wallet
                    </Button>
                  )}
                </div>
                {walletAddress && (
                  <div className='presale-plan-in-button'>
                    <Button variant="contained" onClick={() => { handleDisconnect() }}>
                      Disconnect
                    </Button>
                  </div>
                )}
                <div className='presale-plan-in-button'>
                  <Button variant="contained">Buy now</Button>
                </div>
              </Stack>}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div >
  )
}

export default Header
