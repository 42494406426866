const consts = {
    eth_usdt_contract_address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    sol_usdt_contract_address: "",
    eth_admin_address: "0xe59420447caf80ec5c605ee427356262c71d2067",
    // eth_admin_address: "0xe59420447caf80ec5c605ee427356262c71d2067",
    sol_admin_address: "DMGx6pxX41REYfr4c4PpRUxush1StMdiCDMsAzNSNYF8",
    MainUrl: "https://thorecoin.com/",
    // MainUrl: "http://localhost:3000/",
    Main: "Development",
    BackendUrl: "https://thorecoin.com/api",
    // BackendUrl: "http://localhost:3500/api",
    BlogUrl:"https://blog.thorecoin.com/",
    route: "",
}

module.exports = { consts }