import React from 'react'
import usePageSeo from './UseMetaTag'
export default function MetaTesting() {
    usePageSeo({
        title: 'Meta Testing',
        description: 'Description testing foe meta',
        keywords: ['react', 'seo', 'app'],
        ogTitle: 'React App with SEO',
        ogDescription: 'A simple React app with SEO features',
        ogImage: 'https://imgs.search.brave.com/OQOXld9IPZX0MsGrUP78dgC83suCTOO6blvC22YPFsw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9tbWEu/cHJuZXdzd2lyZS5j/b20vbWVkaWEvMTY3/MzAwNi9NZXRhX0xv/Z28uanBn',
        ogUrl: 'https://example.com/app',
    })
    return (
        <div style={{ textAlign: 'center' }}>
            <h1>Meta Tag Testing</h1>
        </div>
    )
}
