import axios from "axios";
import {consts} from "./constant";
// const token = localStorage.getItem('iphephile')
const Axios = axios.create({
  baseURL: consts.BackendUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type, Authorization",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // "Authorization": token 
  },
 
});
export default Axios;