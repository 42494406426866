import React, { useState, useEffect } from 'react';
import Header from './include/Header'
import Footer from './include/Footer'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import exchangebg from '../images/exchangebg.webp'
import { consts } from '../constant';
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import c1 from '../images/c1.png'
import c2 from '../images/c2.png'
import c3 from '../images/c3.png'
import c4 from '../images/c4.png'
import c5 from '../images/c5.png'
import c6 from '../images/c6.png'
import c7 from '../images/c7.png'
import join from '../images/join.webp'
import phone from '../images/phone.webp'
import robo from '../images/robo.webp'
import prime from '../images/prime.webp'
import rebust from '../images/robust.webp'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { Button } from '@mui/material';
import robo2 from '../images/robo2.webp'

const setHead = () => {
    document.title = "ThoreCoin/exchange";

    // Update meta tags
    const metaDesc = document.querySelector('meta[name="description"]');
    if (metaDesc) {
      metaDesc.setAttribute('content','Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
      ogTitle.setAttribute('content',"ThoreCoin/exchange");
    }

    const ogDesc = document.querySelector('meta[property="og:description"]');
    if (ogDesc) {
      ogDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const ogImage = document.querySelector('meta[property="og:image"]');
    if (ogImage) {
      ogImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
    }

    const ogUrl = document.querySelector('meta[property="og:url"]');
    if (ogUrl) {
      ogUrl.setAttribute('content', `${consts.MainUrl}/exchange` || window.location.href);
    }

    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    if (twitterTitle) {
      twitterTitle.setAttribute('content', "ThoreCoin/exchange");
    }

    const twitterDesc = document.querySelector('meta[name="twitter:description"]');
    if (twitterDesc) {
      twitterDesc.setAttribute('content', 'Thore Networks first meme coin, powered by Thorecoin! This unique project presents a 1OOX opportunity for our community. To add to the excitement, we are incorporating the power of Solana into our ecosystem, ensuring fast and efficient transactions.');
    }

    const twitterImage = document.querySelector('meta[name="twitter:image"]');
    if (twitterImage) {
      twitterImage.setAttribute('content', "https://thorecoin.com/api/images/1720610780829_Frame1402.png");
    }
  };

  setHead();

// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    sidebarcls: {
        borderRadius: '0px !important',
        boxShadow: 'none !important',
        top: '0px',
        padding: '0px !important',
    }

});

const Exchange = () => {

    document.title = "ThoreCoin/exchange";



    const classes = useStyles();

    const [walletss, wallets] = useState("")


    const solProviders = (data) => {
        // setSolProviders(data)
    }

    const eTHProviders = (data) => {
        // setETHProviders(data)
    }
    const [address, setAddress] = useState("")
    const [net, setNet] = useState("")
    useEffect(() => {
        if (net === "") {
            setNet(localStorage.getItem("Network"))
        } else {
            setNet("")
        }
    }, [])

    const card1 = [
        { img: `${c1}`, title: 'SPOT Trading', desc: 'Access real-time market data and trade a wide range of digital assets with precision.' },
        { img: `${c2}`, title: 'P2P Trading', desc: 'Securely trade directly with other users, enjoying the flexibility and privacy of peer-to-peer transactions' },
        { img: `${c3}`, title: 'OTC Trading', desc: 'Execute large-volume trades with our Over-The-Counter service, offering personalized support and competitive rates.' },
    ]
    // ..
    AOS.init();
    return (
        <div className='landing-page-main presale-mail-page'>
            <ToastContainer />
            <Header setToken={net} solProviders={solProviders} eTHProviders={eTHProviders} setAddress={setAddress} wallets={wallets} />

            <div
                className='banner-Thorecoin'
            >

                <Grid container spacing={1} className='thorecoin-banner'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='ex-ban1'>
                        <div className='ex-top-banner' data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1500">
                            <div className='ex-welcome'>
                                Welcome to
                            </div>
                            <div className='ex-main-head'>
                                Thorecoin's Digital Asset Exchange!
                            </div>
                            <div className='ex-main-desc'>
                                We are thrilled to introduce our upcoming digital asset exchange platform, a cutting-edge solution designed to revolutionize your trading experience. Our platform supports the Thore Network ecosystem and is built on the robust Solana blockchain, ensuring fast and secure transactions.
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='display-1' data-aos="fade-left">
                            <div>
                                <div className='ket-feat-ex'>
                                    Key Features
                                </div>
                                <div className='ket-feat-2'>
                                    User-Friendly Interface:
                                </div>
                                <div className='display-1 robust-new'>
                                    <div className='rebust new2'>
                                        <img src={c4} alt='c4' />
                                        <div>
                                            Intuitive design for effortless navigation and trading.
                                        </div>
                                    </div>
                                    <div className='rebust new2'>
                                        <img src={c5} alt='c4' />
                                        <div>
                                            Customizable dashboards tailored to your trading style.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='robo2'>
                                <img src={robo2} />
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='advancetrade margin-top'>
                            Advanced Trading Options:
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='display-1 margin-top'>
                            {card1.map((row, ind) => {
                                return (

                                    <div className='ex-card-1' key={row.id} data-aos="fade-up"
                                        data-aos-anchor-placement="top-bottom">
                                        <img src={row.img} alt={row.img} />
                                        <div className='ex-c-title'>
                                            {row.title}
                                        </div>
                                        <div className='ex-c-desc'>
                                            {row.desc}
                                        </div>
                                    </div>


                                )
                            })}
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='launchpad-banner-1' data-aos="zoom-out-right">
                            <div className='display-2'>
                                <div className='phone'>
                                    <img src={phone} alt='phone' />
                                </div>
                                <div>
                                    <div className='launch-text'>
                                        Launchpad for New Projects:
                                    </div>
                                    <ul className='launch-desc'>
                                        <li>
                                            Support innovative projects with our launchpad, providing a platform for new tokens and blockchain initiatives to reach a global audience.
                                        </li>
                                        <li>
                                            Participate in early-stage investment opportunities and be part of the next big thing in crypto.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='display-2 join' data-aos="fade-up"
                            data-aos-anchor-placement="top-center">
                            <div className='robo-im'>
                                <img src={robo} alt='robo' />
                            </div>
                            <div className='comp-main'>
                                <div className='comp'>
                                    Comprehensive
                                </div>
                                <div className='thore-net-sup'>
                                    Thore Network Support:
                                </div>
                                <ul className='compd-desc'>
                                    <li>
                                        Fully integrated with the Thore Network ecosystem, enhancing utility and connectivity for Thorecoin (THR) and other Thore Network tokens.
                                    </li>
                                    <li>
                                        Seamless interoperability with Thore Network’s DeFi and other services.
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='advancetrade'>
                            Primary Trading Platform on Solana:
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='display-1' data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom">
                            <div className='primary-trade' data-aos="fade-up"
                                data-aos-duration="3000">
                                Built on the Solana blockchain for high-speed, low-cost transactions.
                            </div>
                            <div className='prime' data-aos="flip-left"
                                data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000">
                                <img src={prime} alt='prime' />
                            </div>
                            <div className='primary-trade' data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1500">
                                Leverage Solana's scalability to handle a high volume of trades efficiently.
                            </div>
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='display-2 robo'>
                            <div className='robust'>
                                <img src={rebust} alt='robo' />
                            </div>
                            <div className='robust-banr' >
                                Robust Security Measures:
                            </div>
                            <div className='display-1 robust-right'>
                                <div className='rebust' data-aos="fade-up">
                                    <img src={c6} alt='c4' />
                                    <div>
                                        Advanced security protocols including multi-factor authentication and cold storage.
                                    </div>
                                </div>
                                <div className='rebust' data-aos="fade-down">
                                    <img src={c7} alt='c4' />
                                    <div>
                                        Compliance with global regulations to ensure a safe trading environment.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='display-2 join' data-aos="zoom-in-up">
                            <div className='join-im'>
                                <img src={join} alt='jin' />
                            </div>
                            <div className='joun-div2'>
                                <div className='ex-join'>
                                    Join Us
                                </div>
                                <div className='join-desc'>
                                    Prepare to elevate your trading journey with Thorecoin’s digital asset exchange. Whether you are trading SPOT, engaging in P2P transactions, or exploring OTC deals, our platform is designed to meet all your needs.
                                </div>
                            </div>
                        </div>
                    </Grid>

                </Grid>
                <div className='joinuus-btn'>
                    <div className='join-tex-bt'>
                        Stay tuned for our launch date and be among the first to experience the future of digital asset trading with Thorecoin!
                        <div className='join-brn'>
                            <Button>
                                Join us
                            </Button>
                        </div>
                    </div>


                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Exchange
